import React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../helpers/formattingHelper';
import { Property } from '../../../models/Property';
import { useStrings } from '../../../contexts/StringsContext';

interface ListingProperty {
    property: Property;
}

export const Listing: React.FC<ListingProperty> = ({ property }: ListingProperty) => {
    const strings = useStrings();
    const link = '/property/' + property.id;

    const formattedPriceFiat = formatCurrency(property.price, property.currency);
    const formattedPriceAda = formatCurrency(property.priceAda, { name: 'ADA', symbol: '₳' });

    return (
        <div className="col-md-6 col-lg-4 mb-md-5">
            <Link to={link}>
                <div className="project-item">
                    <div className="project-img">
                        <img src={property.display_picture} className="img-fluid" alt={property.title} />
                    </div>

                    <div className="project-content-box">
                        <div className="project-content-item">
                            <h3>{property.title}</h3>
                            <p className="d-flex align-items-center">
                                <img src="https://marketplace.swayestates.com/img/loc.png" className="img-fluid" alt="" />
                                <span>{property.address}</span>
                            </p>
                        </div>
                        <div className="project-content-item">
                            <h3>{strings.frontpage.property.nftPrice}: {formattedPriceFiat} / {formattedPriceAda}</h3>
                            <p className="d-flex align-items-center">
                                {strings.frontpage.property.available} {property.available} <span className="p--dd">/ {property.nfts}</span>
                            </p>
                            <p className="d-flex align-items-center">
                                <span style={{ color: '#000000', paddingRight: '5px' }}>{strings.frontpage.property.roiLabel}</span> {property.roi}%
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};
