/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

interface UnauthenticatedWarningProps {
    type: string;
}

export const UnauthenticatedWarning: React.FC<UnauthenticatedWarningProps> = ({ type }) => {

    const strings = useStrings();
    let text: string;

    if (type === 'pledge') {
        text = strings.property.notifications.unauthenticatedWarning.messages.pledge;
    } else if (type === 'sell') {
        text = strings.property.notifications.unauthenticatedWarning.messages.sell;
    } else {
        text = strings.property.notifications.unauthenticatedWarning.messages.buy;
    }

    return (
        <div className="alart-s" id="unauthenticatedWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            style={{ cursor: 'pointer' }}
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#unauthenticatedWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>{strings.property.notifications.unauthenticatedWarning.title}</h3>
                        <p>{text}</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.preventDefault();
                            $('#loginModal').modal('show');
                        }}
                    >
                        {strings.property.notifications.unauthenticatedWarning.loginLinkText}
                    </a>
                </div>
            </div>
        </div>
    );
};