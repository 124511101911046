import React from 'react';
import { useStrings } from '../../../contexts/StringsContext';

const HelioErrorNotification: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="helioErrorNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#helioErrorNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>{strings.property.notifications.helioErrorNotification.title}</h3>
                        <p>{strings.property.notifications.helioErrorNotification.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelioErrorNotification;