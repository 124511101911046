/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

export const PledgeFiatModal: React.FC = () => {
    const strings = useStrings();

    return (
        <div
            className="modal fade"
            id="pledgeFiat"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="pledgeFiatLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="pledgeFiatLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <img
                                src={strings.property.notifications.pledgeFiatModal.qrImgSrc}
                                className="img-fluid"
                                alt={strings.property.notifications.pledgeFiatModal.qrImgAlt}
                            />
                        </div>

                        <div className="send-address text-center popup-4p">
                            <h2>
                                {strings.property.notifications.pledgeFiatModal.modalTitle1}<br />{strings.property.notifications.pledgeFiatModal.modalTitle2}<br />{strings.property.notifications.pledgeFiatModal.modalTitle3}
                            </h2>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="popup-5-item popup-border">
                                    <h6>{strings.property.notifications.fiatPurchase.paymentsInsideEU}</h6>
                                    <ul>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.accountHolder}</span> {strings.property.notifications.fiatPurchase.squarely}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.bic}</span> {strings.property.notifications.fiatPurchase.bicCode}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.iban}</span> {strings.property.notifications.fiatPurchase.ibanCode}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.wiseAddressTxt}</span> {strings.property.notifications.fiatPurchase.wiseAddress}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="popup-5-item">
                                    <h6>{strings.property.notifications.fiatPurchase.paymentsOutsideEU}</h6>
                                    <ul>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.accountHolder}</span> {strings.property.notifications.fiatPurchase.squarely}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.accountNumber}</span> {strings.property.notifications.fiatPurchase.accCode}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.sortCodeTxt}</span> {strings.property.notifications.fiatPurchase.sortCode}
                                        </li>
                                        <li>
                                            <span>{strings.property.notifications.fiatPurchase.wiseAddressTxt}</span> {strings.property.notifications.fiatPurchase.wiseAddress}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="popup-done">
                            <a data-bs-dismiss="modal" aria-label="Close">
                                {strings.property.notifications.fiatPurchase.doneButton}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};