import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {API_URL} from "../../config";
import {MetadataItem} from "../nft/Nft";
import {Spinner} from "../common/Spinner";
import {CardanoWallet} from "../../helpers/CardanoWallet";
import {UnauthenticatedWarning} from "../property/componsents/UnauthenticatedWarning";
import {UnverifiedWarning} from "../property/componsents/UnverifiedWarning";
import {ProcessingPaymentModal} from "../property/componsents/ProcessingPaymentModal";
import {PaymentCompleteModal} from "../property/componsents/PaymentCompleteModal";
import {PledgeFiatModal} from "../property/componsents/PledgeFiatModal";
import {NoWalletNotification} from "../property/componsents/NoWalletNotification";
import {PaymentPendingNotification} from "../property/componsents/PaymentPendingNotification";
import {InputsExhaustedNotification} from "../property/componsents/InputsExhaustedNotification";
import {WalletUnauthorizedNotification} from "../property/componsents/WalletUnauthorizedNotification";
import {WrongNetworkNotification} from "../property/componsents/WrongNetworkNotification";
import {PaymentCancelledNotification} from "../property/componsents/PaymentCancelledNotification";
import {GeneralErrorNotification} from "../property/componsents/GeneralErrorNottification";
import {UnauthenticatedModal} from "../login/UnauthenticatedModal";
import {BuyOtherModal} from "../property/componsents/BuyOtherModal";
import axios from "axios";
import {HelioCheckout} from "@heliofi/checkout-react";
import {FeeErrorNotification} from "./componsents/FeeErrorNotification";
import {FeePendingWarning} from "./componsents/FeePendingWarning";
import {FeeCancelledNotification} from "./componsents/FeeCancelledNotification";
import {FeeSuccessModal} from "./componsents/FeeSuccessModal";

import {useStrings} from "../../contexts/StringsContext";

export const ListingFee: React.FC = () => {

    const strings = useStrings();

    let {hash} = useParams();

    let paymentInterval: NodeJS.Timeout | null = null;

    const [property, setProperty] = useState<any>(null);
    const [metadata, setMetadata] = useState<MetadataItem[]>([]);
    const [price, setPrice] = useState<number>(0);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [paymentStatus, setPaymentStatus] = useState<string>('waiting');
    const [qrCode, setQrCode] = useState<string>('');
    const [expiryTime, setExpiryTime] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [lastWallet, setLastWallet] = useState<string>('');
    const [lastSecondaryTransaction, setLastSecondaryTransaction] = useState<any>(null);
    const [currentPaymentId, setCurrentPaymentId] = useState<number>(0);
    const [image, setImage] = useState<string>('');

    const startPayment = async (type: string): Promise<void> => {
        getPaymentInfo(() => {
            setPaymentStatus('pending');
        });
    };

    const eternlPayment = async (): Promise<void> => {

        const walletName: string = 'eternl';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });

    }

    const namiPayment = async (): Promise<void> => {

        const walletName: string = 'nami';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });

    }

    const flintPayment = async (): Promise<void> => {

        const walletName: string = 'flint';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });

    }

    const handlePaymentResponse = (payment: any): void => {

        setLoading(false);

        if (payment?.code) {
            if (payment?.code === 2) {
                cancelPayment();
                $('#paymentCancelledNotification').fadeIn();
            }
        } else if (payment?.indexOf('Transaction error') > -1) {
            cancelPayment();
            if (payment.indexOf('Not enough ADA') > -1 || payment.indexOf('Insufficient') > -1 || payment.indexOf('Exhausted') > -1) {
                $('#inputsExhaustedNotification').fadeIn();//Not enough funds (probably)
            } else {
                $('#generalErrorNotification').fadeIn();//General error?
            }

        } else {
            setPaymentStatus('processing');
            $('#processingPayment').modal('show');
        }
    };

    const cancelPayment = (): void => {
        if (paymentInterval) {
            clearInterval(paymentInterval);
        }
    };

    const getWalletPayment = async (paymentAddress: string, paymentSum: number, walletName: string): Promise<any | null> => {

        $('.notification').hide();
        setLastWallet(walletName);

        const wallet = new CardanoWallet(walletName);
        const isAvailable = wallet.checkForWallet();

        if (!isAvailable) {
            setLoading(false);
            $('#noWalletNotification').fadeIn();
            return null;
        }

        const isEnabled = await wallet.enableWallet();

        if (isEnabled !== true && (isEnabled.status !== true)) {
            setLoading(false);
            $('#walletUnauthorizedNotification').fadeIn();
            return null;
        }

        const networkId = await wallet.getNetworkId();

        if (networkId !== 1) {
            setLoading(false);
            $('#wrongNetworkNotification').fadeIn();
            return null;
        }

        return await wallet.buildSendADATransaction(
            paymentAddress,
            paymentSum
        );
    };

    const getPaymentInfo = async (success: (address: string, price: number) => void): Promise<boolean> => {

        $('.alart-s').hide();

        try {
            const response = await fetch(API_URL + "payment/listingFee/start/" + hash, {
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (response.status !== 200) {
                const errorResponse = await response.json();
                throw {
                    'code': response.status,
                    'message': errorResponse.message
                };
            }

            const res = await response.json();

            setCurrentPaymentId(res.id);
            setPrice(res.price_ada);
            setWalletAddress(res.address);
            setQrCode('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=web+cardano:' + res.address + '?amount=' + res.price);
            setExpiryTime(new Date(res.expires).toISOString());
            startTimer(60 * 60, $('#timeLeft'));
            checkPayment(res.id);
            success(res.address, res.price);
            return Promise.resolve(true);
        } catch (err) {
            setLoading(false);

            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    $('#unauthenticatedModal').modal('show');
                    setPaymentStatus('unauthorized');
                } else if (err.response?.status === 403) {
                    $('#unverifiedWarning').fadeIn();
                    setPaymentStatus('unverified');
                }
            }

            return false;
        }
    };

    const handleWalletException = (e: any): void => {

        setLoading(false);

        cancelPayment();

        if ((e?.message?.indexOf('undefined is not an object (evaluating \'this.Nami.isEnabled\')') > -1 || typeof e === 'undefined') && typeof e !== 'string') {
            $('#noWalletNotification').fadeIn();
        } else if (e?.message?.indexOf('Insufficient') > -1 || (typeof e === 'string' && e?.indexOf('Insufficient') > -1) || (typeof e === 'string' && e?.indexOf('Exhausted') > -1)) {
            $('#inputsExhaustedNotification').fadeIn();
        } else {
            if (e?.code === -3) { //Unauthorized
                $('#walletUnauthorizedNotification').fadeIn();
            } else if (e?.code === -15) { //Wrong network
                $('#wrongNetworkNotification').fadeIn();
            } else if (e?.code === 2) { //Declined
                $('#paymentCancelledNotification').fadeIn();
            } else {
                $('#generalErrorNotification').fadeIn();
            }
        }
    };

    const updateData = (): void => {
        let url = API_URL + "property/" + hash + "/listingFee";
        fetch(url).then(
            res => {
                return res.json();
            }
        ).then(res => {
            setProperty(res);
            setImage(res?.images[0]?.image);
            setMetadata(JSON.parse(res.metadata));
            console.log(res);
        });
    };

    const startTimer = (duration: number, display: any): void => {
        let timer = duration;
        let minutes, seconds;

        const interval = setInterval(() => {
            minutes = parseInt(String(timer / 60), 10);
            seconds = parseInt(String(timer % 60), 10);

            minutes = minutes < 10 ? "0" + minutes : String(minutes);
            seconds = seconds < 10 ? "0" + seconds : String(seconds);

            display.textContent = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    };

    const checkPayment = (paymentId: number): void => {
        paymentInterval = setInterval(() => {
            fetch(API_URL + "payment/listingFee/status/" + paymentId).then(
                res => {
                    return res.json();
                }
            ).then(res => {
                if (res.status === 'success') {
                    setPaymentStatus('success');
                    $('#processingPayment').modal('hide');
                    $('#saleComplete').modal('show');
                    if (paymentInterval !== null) {
                        clearInterval(paymentInterval);
                    }
                    updateData();
                }
            });
        }, 5000);
    };

    const retryPayment = async (): Promise<void> => {
        if (lastWallet === 'nami') {
            return namiPayment();
        } else if (lastWallet === 'eternl') {
            return eternlPayment()
        } else if (lastWallet === 'flint') {
            return flintPayment();
        }
    };

    useEffect(() => {
        updateData();
    }, []);

    const HelioCheckoutComponent = () => {

        const feeSuccess = (): void => {
            $('#feeSuccessModal').modal('show');
        };

        const feeError = (): void => {
            $('#feeErrorNotification').fadeIn();
        };

        const feePending = (): void => {
            $('#feePendingWarning').fadeIn();
        };

        const feeCancel = () => {
            $('#feeCancelNotification').fadeIn();
        };

        const helioConfig: any = useMemo(() => {
            return {
                paylinkId: "65f35ff66b5e964a16b0e2c8",
                amount: String(property?.priceEur),
                display: "button",
                onSuccess: feeSuccess,
                onError: feeError,
                onPending: feePending,
                onCancel: feeCancel,
                onStartPayment: () => console.log("Starting payment"),
                paymentType: "PAYLINK",
                network: "test",
                additionalJSON: { property: property?.id }
            };
        }, []);

        return (
            <>
                <HelioCheckout config={helioConfig} />
            </>
        );
    };

    if (!property) {
        return (<Spinner />);
    }

    return (
        <>
            <div className="wlc-bg1" style={{ backgroundPosition: '300px 50%' }}>
                <div className="container">
                    <div className="buy-nfty-tokens" style={{ minHeight: 'unset' }}>
                        <div className="popup-form-title">
                            <h2>{strings.listingFee.title}</h2>
                            <h5>{property?.address}</h5>
                        </div>
                    </div>
                    <div className="test-street-area properties">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 order-md-0 order-1">
                                <div className="buynotes-form">
                                    <div className="nfty-info w-100">
                                        <p className="nfty-type">{strings.listingFee.nftsLabel}: <span>{property?.nfts}</span></p>
                                        <p className="nfty-type">{strings.listingFee.pricePerNft}: <span>{property?.price} €</span></p>
                                        <div>
                                            {metadata.map((value, key) => (
                                                <p key={'trait-' + key} className='nfty-term'>{value.title}: <span>{value.value}</span></p>
                                            ))}
                                        </div>
                                        <a href={`https://nftworld.mypinata.cloud/ipfs/${property.deed}`} target="_blank" rel="noreferrer" className="contract-details-link">
                                            {strings.listingFee.propertyDeedLinkText}
                                        </a>
                                    </div>
                                    <div className="test-stast-right-price mt-4">
                                        <p style={{ margin: '0' }}>{strings.listingFee.listingFeeLabel}</p>
                                        <ul style={{ marginBottom: '0' }}>
                                            <li><span>$</span> {property?.priceEur}</li>
                                            <li>/ <span>ADA</span> {property?.priceAda}</li>
                                        </ul>
                                    </div>
                                    <div className="test-stast-right-btn mt-4">
                                        <div className="row">
                                            <div className="dropdown wallet-selection col-lg-8 mb-3">
                                                <button className="button btn dropdown-toggle selection-button" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {strings.listingFee.selectWallet} <i id="icon" className="fa fa-chevron-down"></i>
                                                </button>
                                                <div className="dropdown-menu wallet-dropdown px-2 pb-0" aria-labelledby="dropdownMenuButton">
                                                    <ul className="wallet-dropdownbtns">
                                                        <li>
                                                            <a className='button btn-nami' onClick={namiPayment}>{strings.listingFee.buyWithNami}</a>
                                                        </li>
                                                        <li>
                                                            <a className='button btn-eternl' onClick={eternlPayment}>{strings.listingFee.buyWithEternl}</a>
                                                        </li>
                                                        <li>
                                                            <a className='button btn-flint' onClick={flintPayment}>{strings.listingFee.buyWithFlint}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="col-lg-4 test-stast-right-btn mb-3">
                                                <li>
                                                    <a className={'white button btn-other'} onClick={() => { startPayment('sale'); }}>
                                                        {strings.listingFee.buyWithOtherWallet}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <ul className="col-lg-12 mb-3">
                                                <li>
                                                    <HelioCheckoutComponent />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 order-md-1 order-0">
                                <div className="test-street-right-item">
                                    <div className="test-light-box-img">
                                        <img src={image} className="img-fluid roundedImage" alt="" />
                                    </div>
                                    <div className="test-street-left-slider mt-2">
                                        <div className="container-flui pt-1">
                                            <div className="slider-inner">
                                                <div className="carousel">
                                                    <input defaultChecked={true} className="carousel__activator" id="carousel-slide-activator-1" name="carousel" type="radio" />
                                                    <input className="carousel__activator" id="carousel-slide-activator-2" name="carousel" type="radio" />
                                                    <input className="carousel__activator" id="carousel-slide-activator-3" name="carousel" type="radio" />
                                                    <div className="carousel__controls">
                                                        <label className="carousel__control carousel__control--forward" htmlFor="carousel-slide-activator-2">
                                                            &#10095;
                                                        </label>
                                                    </div>
                                                    <div className="carousel__controls">
                                                        <label className="carousel__control carousel__control--backward" htmlFor="carousel-slide-activator-1">
                                                            &#10094;
                                                        </label>
                                                        <label className="carousel__control carousel__control--forward" htmlFor="carousel-slide-activator-3">
                                                            &#10095;
                                                        </label>
                                                    </div>
                                                    <div className="carousel__controls">
                                                        <label className="carousel__control carousel__control--backward" htmlFor="carousel-slide-activator-2">
                                                            &#10094;
                                                        </label>
                                                    </div>
                                                    <div className="carousel__screen">
                                                        <div className="carousel__track">
                                                            {property.images.map((item: any, key: number) => (
                                                                <div onClick={() => { setImage(item.image) }} className="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-4" key={`image-${key}`}>
                                                                    <div className="demo-content">
                                                                        <img src={item.image} alt="" className="img-fluid roundedThumbnail" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {paymentStatus === 'pending' && (
                <BuyOtherModal
                    qrCode={qrCode}
                    wallet={walletAddress}
                    price={String(price)}
                    expiry={Number(expiryTime)}
                    exchangeWarning={false}
                />
            )}
            <UnauthenticatedWarning type={''} />
            <UnverifiedWarning type={''} />
            <ProcessingPaymentModal />
            <PaymentCompleteModal type={'listing'} />
            <PledgeFiatModal />
            <NoWalletNotification />
            <PaymentPendingNotification />
            <InputsExhaustedNotification retry={retryPayment} />
            <WalletUnauthorizedNotification retry={retryPayment} name={lastWallet} />
            <WrongNetworkNotification retry={retryPayment} />
            <PaymentCancelledNotification retry={retryPayment} />
            <GeneralErrorNotification retry={retryPayment} />
            <UnauthenticatedModal />
            <FeeErrorNotification />
            <FeePendingWarning />
            <FeeCancelledNotification />
            <FeeSuccessModal />
        </>
    );

};