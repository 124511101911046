/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

interface PaymentCompleteModalProps {
    type: string;
}

export const PaymentCompleteModal: React.FC<PaymentCompleteModalProps> = ({ type }) => {
    const strings = useStrings();

    const renderText = () => {

        if (type === 'pledge') {
            return (
                <div className="send-address text-center popup-4p">
                    <h2>{strings.property.notifications.paymentCompleteModal.pledgeTitle}</h2>
                    <p>
                        {strings.property.notifications.paymentCompleteModal.pledgeMessage1}<br />{strings.property.notifications.paymentCompleteModal.pledgeMessage2}
                    </p>
                </div>
            );
        } else if (type === 'listing') {
            return (
                <div className="send-address text-center popup-4p">
                    <h2>{strings.property.notifications.paymentCompleteModal.defaultTitle}</h2>
                    <p>
                        {strings.property.notifications.paymentCompleteModal.listingMessage1}<br />{strings.property.notifications.paymentCompleteModal.listingMessage2}
                    </p>
                </div>
            );
        }

        return (
            <div className="send-address text-center popup-4p">
                <h2>{strings.property.notifications.paymentCompleteModal.defaultTitle}</h2>
                <p>
                    {strings.property.notifications.paymentCompleteModal.defaultMessage1}<br />{strings.property.notifications.paymentCompleteModal.defaultMessage2}
                </p>
            </div>
        );

    }

    return (
        <div
            className="modal fade"
            id="paymentComplete"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="paymentCompleteLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="paymentCompleteLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <img
                                src={strings.property.notifications.paymentCompleteModal.qrImg}
                                className="img-fluid"
                                alt={strings.property.notifications.paymentCompleteModal.qrImgAlt}
                            />
                        </div>
                        {renderText()}
                        <div className="popup-done">
                            <a data-bs-dismiss="modal" aria-label="Close">
                                {strings.property.notifications.paymentCompleteModal.doneButton}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};