import React, { useState } from 'react';
import { Formik } from 'formik';
import {useStrings} from "../../../contexts/StringsContext";

interface SetAddressModalProps {
    setAddress: (address: string) => void;
}

interface SetAddressModalFormValues {
    address: string;
}

export const SetAddressModal: React.FC<SetAddressModalProps> = ({ setAddress }) => {
    const strings = useStrings();
    const [resetField, setResetField] = useState<boolean>(false);

    const onSubmit = async (values: SetAddressModalFormValues): Promise<void> => {
        setAddress(values.address);
        $('#setAddressModal').modal('hide');
        localStorage.setItem('walletAddress', values.address);
    };

    const handleReset = (): void => {
        setResetField(true);
        setTimeout(() => {
            setResetField(false);
        }, 3600000);
        localStorage.removeItem('walletAddress');
    };

    return (
        <div
            className="modal fade"
            id="setAddressModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="loginModal"
            aria-hidden="true"
        >
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="loginModal"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body" style={{ marginTop: '100px' }}>
                        <div className="sign-in-popup-form">
                            <div className="qr-img text-center pb-4">
                                <img
                                    src="https://marketplace.swayestates.com/img/qr1.png"
                                    className="img-fluid rotate"
                                    alt=""
                                />
                            </div>
                            <div className="popup-form-title">
                                <h3 style={{ textAlign: 'center' }}>{strings.myNfties.setAddressModal.title}</h3>
                                <p style={{ textAlign: 'left', marginTop: '20px' }}>
                                    {strings.myNfties.setAddressModal.insertWalletAddress}
                                </p>
                            </div>
                            <Formik
                                initialValues={{ address: '' }}
                                validate={(values: SetAddressModalFormValues) => {
                                    const errors = {} as SetAddressModalFormValues;
                                    if (!values.address) {
                                        errors.address = strings.myNfties.setAddressModal.required;
                                    }
                                    return errors;
                                }}
                                onSubmit={onSubmit} >
                                {({ handleSubmit, handleChange, values, errors, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="popup-sign-item">
                                            <label htmlFor="email">
                                                {strings.myNfties.setAddressModal.addressLabel}{' '}
                                                {errors.address && touched.address && (
                                                    <span style={{ color: 'red' }}>{errors.address}</span>
                                                )}
                                            </label>
                                            <input
                                                name="address"
                                                className="form-value-color"
                                                id="asif-pl"
                                                placeholder=""
                                                value={resetField ? '' : values.address}
                                                onChange={(e) => {
                                                    if (resetField) {
                                                        handleChange('address')('');
                                                        setResetField(false);
                                                    } else {
                                                        handleChange(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="popup-sign-in-submite wcl-btn d-flex gap-3 justify-content-center">
                                            <button type="submit">{strings.myNfties.setAddressModal.connectButton}</button>
                                            <button type="button" onClick={handleReset}>{strings.myNfties.setAddressModal.resetButton}</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};