import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

interface SaleCompleteModalProps {
    type: 'sale' | 'note' | 'other';
}

export const SaleCompleteModal: React.FC<SaleCompleteModalProps> = ({ type }) => {
    const strings = useStrings();

    const close = () => {
        $('#saleComplete').modal('toggle');
        $('.modal-backdrop').hide();
    };

    return (
        <div className="modal fade" id="saleComplete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
             aria-labelledby="paymentCompleteLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="paymentCompleteLabel"></strong>
                        <a data-bs-dismiss="modal" onClick={close} aria-label="Close"><i className="fa-solid fa-x"></i></a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <img src="https://marketplace.swayestates.com/img/img/qr2s.png" className="img-fluid" alt="" />
                        </div>

                        <div className="send-address text-center popup-4p">
                            <h2>{strings.notifications.saleComplete.congratulations}</h2>
                            {type === 'sale' && <p>{strings.notifications.saleComplete.saleMessage}</p>}
                            {type !== 'sale' && <p>{strings.notifications.saleComplete.otherMessage}</p>}
                        </div>

                        <div className="popup-done">
                            <a onClick={close} data-bs-dismiss="modal" aria-label="Close">{strings.notifications.saleComplete.doneButton}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};