import React, { Suspense } from "react";
import { tabs } from "../../data/frontpage";
import { Link } from "react-router-dom";
import { InterestForm } from "./components/InterestForm";
import { Spinner } from "../common/Spinner";
import { useStrings } from "../../contexts/StringsContext";

interface ComingSoonProps {
    page: string;
}

export const ComingSoon: React.FC<ComingSoonProps> = ({ page }) => {
    const activeTab: number = page === "sell" ? 1 : 2;
    const pageClassName: string = page === "sell" ? "sell-tab" : "rent-tab";
    const strings = useStrings();

    return (
        <div key={'comingsoon-' + page} className={`wlc-bg1 ${pageClassName}`}>
            <section>
                <div className="container">
                    <div className="project-menu coming-soon-menu">
                        <div className="project-m-item1">
                            <strong></strong>
                        </div>
                        <div className="project-m-item2">
                            <ul>
                                {tabs.map((tab, index) => {
                                    const linkClassName: string = activeTab === index ? "active-m" : "";
                                    const listClassName: string = index === tabs.length - 1 ? "p-menu" : "";

                                    return (
                                        <li key={tab.name} className={listClassName}>
                                            <Link to={tab.link} className={linkClassName}>
                                                {tab.name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <div className="sign-in-popup-form">
                            {page === "sell" && (
                                <div className="popup-form-title">
                                    <h2>{strings.comingSoon.sell.title}</h2>
                                    <p style={{ textAlign: "left" }}>
                                        {strings.comingSoon.sell.paragraph1}
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        <ul style={{ listStyleType: "circle" }}>
                                            <li>{strings.comingSoon.sell.paragraph2}</li>
                                            <li>{strings.comingSoon.sell.paragraph3}</li>
                                            <li>{strings.comingSoon.sell.paragraph4}</li>
                                            <li>{strings.comingSoon.sell.paragraph5}</li>
                                        </ul>
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        {strings.comingSoon.sell.paragraph6Part1}
                                        <i>{strings.comingSoon.sell.paragraph6Part2}</i>
                                        {strings.comingSoon.sell.paragraph6Part3}
                                    </p>
                                </div>
                            )}
                            {page !== "sell" && (
                                <div className="popup-form-title">
                                    <h2>{strings.comingSoon.rent.title}</h2>
                                    <p style={{ textAlign: "left" }}>
                                        {strings.comingSoon.rent.paragraph1Part1}
                                        <i>{strings.comingSoon.sell.paragraph6Part2}</i>
                                        {strings.comingSoon.rent.paragraph1Part2}
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        {strings.comingSoon.rent.paragraph2}
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        {strings.comingSoon.rent.paragraph3}
                                    </p>
                                </div>
                            )}
                            <Suspense fallback={<Spinner />}>
                                <InterestForm page={page} />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
