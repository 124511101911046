import React, { Suspense } from "react";
import { Spinner } from "../components/common/Spinner";
import { AffiliateForm } from "./components/AffiliteForm";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useStrings } from "../contexts/StringsContext";

export const Affiliate: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="wlc-bg1">
            <HelmetProvider>
                <Helmet>
                    <title>{strings.affiliate.meta.title}</title>
                    <meta name="description" content={strings.affiliate.meta.description} />
                    <meta property="og:title" content={strings.meta.ogTitle} />
                    <meta property="og:image" content={strings.meta.ogImage} />
                </Helmet>
            </HelmetProvider>
            <span className="wel-page-left-arrow mobile-left-arrow comin-mobile-left-arrow">
                <img src={strings.comingSoon.images.mobileLeftArrow} className="img-fluid" alt={strings.comingSoon.images.mobLeftArrowPatternAlt} />
            </span>
            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <div className="sign-in-popup-form">
                            <div className="popup-form-title">
                                <h2>{strings.affiliate.content.congratsTitle}</h2>
                                <p style={{ textAlign: 'left' }}>{strings.affiliate.content.paragraph1}</p>
                                <p style={{ textAlign: 'left' }}>{strings.affiliate.content.paragraph2}</p>
                                <p style={{ textAlign: 'left' }}>{strings.affiliate.content.paragraph3}</p>
                                <p style={{ textAlign: 'left' }}>
                                    {strings.affiliate.content.paragraph4}
                                    <i>{strings.affiliate.content.paragraph4Italic}</i>
                                    {strings.affiliate.content.paragraph4End}
                                </p>
                            </div>
                            <Suspense fallback={<Spinner />}>
                                <AffiliateForm />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
};