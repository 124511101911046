/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from "../../config";
import { LoginModal } from "../login/LoginModal";
import { MenuItem } from "../../models/MenuItem";
import axiosInstance from '../../api/axiosConfig';
import { useStrings } from '../../contexts/StringsContext';

export const Header: React.FC = () => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const strings = useStrings();

    useEffect(() => {
        axiosInstance.get(API_URL + 'menu')
            .then((response) => {
                setMenuItems(response.data);
            })
            .catch((error) => {
                console.error('Error fetching menu items:', error);
            });
    }, []);

    const logout = (): void => {
        axiosInstance.post(API_URL + 'logout')
            .catch((err) => {
                console.error('Logout error:', err);
            })
            .finally(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                //window.location.reload();
            });

        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.reload();
    };
    console.log('AABBCC', strings);
    return (
        <Fragment>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <strong className="offcanvas-title" id="offcanvasRightLabel"></strong>
                    <a href='/#' className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                        <span aria-hidden="true" style={{ fontSize: "0" }}>{strings?.header?.menuClose}</span>
                    </a>
                </div>
                <div className="offcanvas-body">
                    <div className="mobile-menu">
                        <ul>
                            <li><a href={strings.header.logoLink}>{strings.header.homeText}</a></li>
                            {menuItems.map(menu => {
                                return (
                                    <li key={menu.name}><a href={menu.url}>{menu.name}</a></li>
                                )
                            })}
                            {!localStorage.getItem("token") &&
                                <li><a href="" onClick={(e) => {
                                    e.preventDefault();
                                    $('#loginModal').modal('show');
                                }}>{strings.header.loginText}</a></li>
                            }
                            {localStorage.getItem("token") &&
                                <>
                                    <li><Link to="/" className="marketplace d-block d-lg-none">{strings.header.marketplaceText}</Link></li>
                                    <li><Link to="profile" className="profile d-block d-lg-none">{strings.header.profileText}</Link></li>
                                    <li><Link to="myNfties" className="properties d-block d-lg-none">{strings.header.myEstatesText}</Link></li>
                                    <li><a href="" onClick={logout}>{strings.header.logoutText}</a></li>
                                </>
                            }
                        </ul>
                    </div>
                    <div className="monile-btn">
                        <Link to="register" className="joinus">{strings.header.joinUsText}</Link> <br />
                        <Link to="affiliate" className="affiliate">{strings.header.affiliateText}</Link>
                    </div>
                </div>
            </div>

            <header className="h-padding">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-baseline pad-15">
                        <div className="logo-full-container">
                            <a href={strings.header.logoLink}>
                                <img src={strings.header.logoSrc} width={174} alt={strings.header.logoAltText} />
                            </a>
                        </div>
                        <nav className="project-page-nav py-4">
                            <div className="logo">
                                <Link to="/"><img src={strings.header.logoSrc} width={144} className="img-fluid" alt={strings.header.logoAltText} /></Link>
                            </div>
                            <div className="menu-btn">
                                <div className="menu-items">
                                    <a className="howtoguide" href={strings.header.howToGuideUrl} title={strings.header.howToGuideText}>
                                        {strings.header.howToGuideText}
                                    </a>
                                    {!localStorage.getItem("token") &&
                                        <Fragment>
                                            <Link to="/" className="marketplace">{strings.header.marketplaceText}</Link>
                                            <Link to="register" className="joinus">{strings.header.joinUsText}</Link>
                                            <a href="" className="login" onClick={(e) => {
                                                e.preventDefault();
                                                $('#loginModal').modal('show');
                                            }}>{strings.header.loginText}</a>
                                        </Fragment>
                                    }
                                    {localStorage.getItem("token") &&
                                        <Fragment>
                                            <Link to="/" className="marketplace">{strings.header.marketplaceText}</Link>
                                            <Link to="profile" className="profile">{strings.header.profileText}</Link>
                                            <Link to="myNfties" className="properties">{strings.header.myEstatesText}</Link>
                                            <a href="" onClick={logout} className="logout">{strings.header.logoutText}</a>
                                        </Fragment>
                                    }
                                    <div className="hamburger-menu">
                                        <a href='/#' className="menu-icon" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                                            className="fa-solid fa-bars"></i></a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <LoginModal />
        </Fragment>
    );
};