import React, { createContext, useContext, ReactNode } from 'react';
import nftyStrings from '../strings/nfty.json';
import swayStrings from '../strings/sway.json';
import {WHITELABEL_NAME} from "../config";

const StringsContext = createContext<any>({});

export const StringsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    let strings;

    if (WHITELABEL_NAME as string === 'nfty') {
        strings = nftyStrings;
    } else if (WHITELABEL_NAME as string === 'sway') {
        strings = swayStrings;
    } else {
        strings = {};
    }

    return (
        <StringsContext.Provider value={strings}>
            {children}
        </StringsContext.Provider>
    );
};

export const useStrings = () => useContext(StringsContext);
