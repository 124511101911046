import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

export const PaymentPendingNotification: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="paymentPendingWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#paymentPendingWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>{strings.property.notifications.paymentPendingNotification.title}</h3>
                        <p>{strings.property.notifications.paymentPendingNotification.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};