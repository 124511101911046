import React from "react";
import { Link } from "react-router-dom";
import { useStrings } from "../../contexts/StringsContext";

export const UnauthenticatedModal: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="modal buyers-modal" id={'unauthenticatedModal'} tabIndex={-1} role="dialog"
             style={{ display: "none" }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="loginModal"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-x"></i></a>
                    </div>
                    <div className="modal-body">
                        <div className="popup-form-title">
                            <h2>{strings.unauthenticatedModal.sorryTitle}</h2>
                            <p>{strings.unauthenticatedModal.sorryMessage}</p>
                        </div>
                        <div className="popup-sign-in-submite">
                            <button type="button" onClick={() => {
                                $('#unauthenticatedModal').modal('hide')
                                $('#loginModal').modal('show');
                            }}>
                                {strings.login.buttons.submit}
                            </button>
                            <button type="button" className="register">
                                <Link onClick={() => { $('#unauthenticatedModal').modal('hide') }} to={'../register'} className="text-white">
                                    {strings.unauthenticatedModal.joinUs}
                                </Link> <br />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
