import React from 'react';
import {Routes, Route} from "react-router-dom";
import {BrowserRouter} from 'react-router-dom'
// import logo from './logo.svg';
import './App.css';
import {Property} from "./components/property/Property";
import {Header} from "./components/common/Header";
import {Footer} from "./components/common/Footer";
import {Frontpage} from "./components/listings/Frontpage";
import {Register} from "./components/register/Register";
import {ComingSoon} from "./components/comingsoon/ComingSoon";
import {Forgot} from "./components/forgot/Forgot";
import {Affiliate} from "./affiliate/Affiliate";
import {ConfirmEmail} from "./components/confirmEmail/ConfirmEmail";
import {Reset} from "./components/forgot/Reset";
import {Profile} from "./components/profile/Profile";
import {UpdateToken} from "./components/common/UpdateToken";
import {Verify} from "./components/verify/Verify";
import {MyNfties} from "./components/myNfties/MyNfties";
import {Nft} from "./components/nft/Nft";
import {Note} from "./components/nft/Note";
import {GetInvolved} from "./components/getInvolved/GetInvolved";
import {BuyNftyNotes} from './components/getInvolved/BuyNftyNotes';
import {ListingFee} from './components/listingFee/ListingFee';
import {StringsProvider} from "./contexts/StringsContext";

function App() {
    return (
        <BrowserRouter>
            <StringsProvider>
                <div className="App" id="wrapper">
                    <Header/>
                    <UpdateToken/>
                    <Routes>
                        <Route path="/" element={<Frontpage showLogin={false}/>}/>
                        <Route path="property/:propertyId" element={<Property/>}/>
                        <Route path="sell" element={<ComingSoon page={'sell'}/>}/>
                        <Route path="rent" element={<ComingSoon page={'rent'}/>}/>
                        <Route path="register" element={<Register/>}/>
                        <Route path="login" element={<Frontpage showLogin={true}/>}/>
                        <Route path="forgot" element={<Forgot/>}/>
                        <Route path="affiliate" element={<Affiliate/>}/>
                        <Route path="confirmEmail/:code" element={<ConfirmEmail/>}/>
                        <Route path="resetPassword/:code" element={<Reset/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="verify" element={<Verify/>}/>
                        <Route path="myNfties" element={<MyNfties/>}/>
                        <Route path="nft/:nftId" element={<Nft/>}/>
                        <Route path="listingFee/:hash" element={<ListingFee/>}/>
                    </Routes>
                    <Footer/>
                </div>
            </StringsProvider>
        </BrowserRouter>
    );
}

export default App;
