import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import { useNavigate } from "react-router";
import { User } from "../../models/User";
// @ts-ignore
import { Veriff } from '@veriff/js-sdk';
import axiosInstance from "../../api/axiosConfig";
import { useStrings } from "../../contexts/StringsContext";

declare global {
    interface Window {
        veriffSDK: any;
    }
}

export const Verify: React.FC = () => {

    const navigate = useNavigate();
    const [userData, setUserData] = useState<User | null>(null);
    // const [files, setFiles] = useState(new Map<string, File>());
    const [loading, setLoading] = useState<boolean>(false);
    const strings = useStrings();

    useEffect(() => {
        axiosInstance.get(API_URL + "profile")
            .then((response) => {
                const user = response.data as User;
                setUserData(user);

                if (user.is_verified) {
                    navigate("/profile");
                }

                $('[data-toggle="tooltip"]').tooltip();
            })
            .catch(() => {
                navigate("/");
            });
    }, [navigate]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.veriff.me/incontext/js/v1/veriff.js";
        document.getElementsByTagName("head")[0].appendChild(script);
        const veriff = Veriff({
            host: 'https://stationapi.veriff.com',
            apiKey: "20fdf410-b402-4427-a043-4fdf06f6bb74",
            parentId: "veriff-root",
            onSession: function (err, response) {
                window.veriffSDK.createVeriffFrame({
                    url: response.verification.url,
                    onEvent: function (msg) {
                        if (msg === 'FINISHED') {
                            setLoading(true);
                            setTimeout(() => {
                                fetch(API_URL + "profile?token=" + localStorage.getItem("token"))
                                    .then((response) => response.json())
                                    .then((response: User) => {
                                        setUserData(response);
                                        if (response?.kyc_status) {
                                            setLoading(false);
                                        }
                                    });
                            });

                        }
                    }
                });
            }
        });
        // Prefill data from userData into the Veriff form
        if (userData) {
            // Splitting the name to get first and last name
            const nameParts = userData.name.split(" ");
            const firstName = nameParts.slice(0, -1).join(" ");
            const lastName = nameParts.slice(-1).join(" ");

            veriff.setParams({
                person: {
                    givenName: firstName || "",
                    lastName: lastName || ""
                },
                vendorData: userData.email || ""
            });
        }
        veriff.mount({
            formLabel: {
                givenName: 'Firstname',
                lastName: 'Lastname',
                vendorData: 'Email Address'
            },
            submitBtnText: 'Get verified',
            loadingText: 'Please wait...'
        });

        return () => {
            if (veriff.unmount) {
                veriff.unmount();
            }
        };
    }, [userData]);

    useEffect(() => {
        const interval = setInterval(() => {

            fetch(API_URL + "profile?token=" + localStorage.getItem("token"))
                .then((response) => response.json())
                .then((response: User) => {
                    setUserData(response);
                    if (response?.kyc_status) {
                        setLoading(false);
                    }
                });

        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="wlc-bg1">
            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <div className="sign-in-popup-form">
                            <div className="popup-form-title">
                                <h2>{strings.verify.form.title}</h2>
                                <p style={{ textAlign: 'left' }}>
                                    {strings.verify.form.description1}
                                </p>
                                <p style={{ textAlign: 'left' }}>
                                    {strings.verify.form.description2}
                                </p>
                            </div>
                            {loading && (
                                <div className="alert alert-info" role="alert">
                                    {strings.verify.form.alerts.loading}
                                </div>
                            )}
                            {userData?.kyc_status === "approved" && (
                                <div className="alert alert-success" role="alert">
                                    {strings.verify.form.alerts.success}
                                </div>
                            )}
                            {userData?.kyc_status && userData?.kyc_status !== "approved" && (
                                <div className="alert alert-danger" role="alert">
                                    {strings.verify.form.alerts.rejected}
                                </div>
                            )}

                            <div className="row mb-5">
                                <div className="col-lg-12 mx-auto">
                                    <div className="card">
                                        <div className="card-body">
                                            <div id="veriff-root" className="popup-sign-item"
                                                style={{ maxWidth: '100%' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};