import React, { Suspense, useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { Link } from 'react-router-dom';
import { Listings } from './components/Listings';
import { tabs } from '../../data/frontpage';
import { Spinner } from "../../components/common/Spinner";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axiosInstance from '../../api/axiosConfig';
import {useStrings} from "../../contexts/StringsContext";

interface FrontpageProps {
    showLogin: boolean;
}

export const Frontpage: React.FC<FrontpageProps> = ({ showLogin }) => {

    const strings = useStrings();

    const [activeTab] = useState<number>(0);

    useEffect(() => {
        if (showLogin) {
            axiosInstance.get(API_URL + "profile")
                .then((response) => {
                    if (response.status !== 200) {
                        jQuery('#loginModal').modal('show');
                    }
                })
                .catch(() => {
                    jQuery('#loginModal').modal('show');
                });
        }
    }, [showLogin]);

    return (
        <section className="project-area">
            <HelmetProvider>
                <Helmet>
                    <title>{strings.meta.title}</title>
                    <meta name="description" content={strings.meta.description} />
                    <meta property="og:title" content={strings.meta.ogTitle} />
                    <meta property="og:image" content={strings.meta.ogImage} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className="project-menu">
                    <div className="project-m-item1">
                        <h2>{strings.frontpage.launchingSoon}</h2>
                    </div>
                    <div className="project-m-item2">
                        <ul>
                            {tabs.map((tab, index) => {
                                const linkClassName = activeTab === index ? 'active-m' : '';
                                const listClassName = index === tabs.length - 1 ? 'p-menu' : '';

                                return (
                                    <li key={tab.name} className={listClassName}>
                                        <Link to={tab.link} className={linkClassName}>
                                            {tab.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <Suspense fallback={<Spinner />}>
                    <Listings />
                </Suspense>
            </div>
        </section>
    );
};