import React from "react";
import { useStrings } from "../../contexts/StringsContext";

export const Spinner: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="loading-overlay">
            <div className="loader">
                <div className="border"></div>
                <div className="spinner"></div>
                <div className="text">{strings.spinner.loadingText}</div>
            </div>
        </div>
    );
};
