import React from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../../../models/Property';

interface MyPropertyListingProps {
    property: Property;
    key: string;
}

export const MyPropertyListing: React.FC<MyPropertyListingProps> = ({property}:MyPropertyListingProps) => {
    const link: string = '/nft/' + property.id;
    const displayPicture: any = 'https://nftworld.mypinata.cloud/ipfs/' + property.display_picture;

    return (
        <div className="col-md-6 col-lg-4" key={property.id}>
            <Link to={link}>
                <div className="project-item">
                    <div className="project-img">
                        <img src={displayPicture} className="img-fluid" alt={property.title} />
                    </div>
                    <div className="project-content-box" style={{ top: '75%' }}>
                        <div className="project-content-item">
                            <h3>{property.title}</h3>
                            <p className="d-flex align-items-center" style={{ color: 'black', marginTop: 10 }}>
                                <img src="https://marketplace.swayestates.com/img/loc.png" className="img-fluid" alt="" />
                                <p>{property.address}</p>
                            </p>
                        </div>
                        <div className="project-content-item">{/* Additional content here */}</div>
                    </div>
                </div>
            </Link>
        </div>
    );
};