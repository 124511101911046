import React, { Fragment, useState, useEffect } from 'react';
import { API_URL } from '../../../config';
// import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Country } from "../../../models/Country";
import { FormErrors } from "../../../models/FormErrors";
import axiosInstance from '../../../api/axiosConfig';
import { useStrings } from '../../../contexts/StringsContext';

export interface InterestFormProps {
    page: string;
}

interface InterestFormValues {
    name: string;
    email: string;
    areaCode: string;
    phone: string;
    country: number;
    propertyName?: string;
    city?: string;
    postCode?: string;
    street?: string;
    propertyCountry?: number;
    price?: string;
    morgage?: 'yes' | 'no';
    listed?: 'yes' | 'no';
    saleType?: 'part' | 'all';
    percentage?: string;
}

export const InterestForm: React.FC<InterestFormProps> = ({ page }) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState(false);
    const [countries, setCountries] = useState<Country[]>([]);
    const strings = useStrings();

    useEffect(() => {
        axiosInstance.get(API_URL + "countries")
            .then(res => {
                setCountries(res.data);
            })
            .catch(error => {
                console.error('Error fetching countries:', error);
            });
    }, []);

    const validateEmail = (email: string): boolean => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    };

    const onSubmit = async (values: InterestFormValues): Promise<void> => {
        console.log('Submit', values);
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        axiosInstance.post(API_URL + 'interest', formData)
            .then((response) => {
            if (response.data?.status !== 'ok') {
                setSuccess(false);
                setErrors(response.data.errors || []);
            } else {
                setErrors([]);
                setSuccess(true);
            }
        })
        .catch((error) => {
            console.error('Error submitting interest form:', error);
            setSuccess(false);
            setErrors([strings.interestForm.alerts.errorMessage]);
        });
    };

    return (
        <Fragment>
            {errors.length > 0 && (
                <div className="alert alert-danger" role="alert">
                    {errors.map((error, index) => (
                        <span key={`error${index}`}>{error}</span>
                    ))}
                </div>
            )}
            {success && (
                <div className="alert alert-success" role="alert">
                    {strings.interestForm.alerts.successTitle}
                    <i>{strings.interestForm.alerts.successItalic}</i>
                    <br />
                    <br />
                    {strings.interestForm.alerts.successMessage}
                </div>
            )}
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    areaCode: '',
                    phone: '',
                    country: 1,
                    propertyName: '',
                    city: '',
                    postCode: '',
                    street: '',
                    propertyCountry: 1,
                    price: '',
                    morgage: 'no',
                    listed: 'no',
                    saleType: 'all',
                    percentage: '',
                }}
                validate={(values: InterestFormValues) => {
                    const requiredFields = [
                        'name',
                        'areaCode',
                        'email',
                        'phone',
                    ];

                    if (page === 'sell') {
                        requiredFields.push('city');
                        requiredFields.push('postCode');
                        requiredFields.push('street');
                        requiredFields.push('price');
                    }

                    const errors: Partial<FormErrors> = {};

                    requiredFields.forEach((field) => {
                        if (!values[field]) {
                            errors[field] = 'Required';
                        }
                    });

                    if (!values?.email) {
                        errors.email = 'Required';
                    } else if (!validateEmail(values.email as string)) {
                        errors.email = 'Invalid e-mail';
                    }

                    return errors;
                }}
                onSubmit={onSubmit} >
                {({ handleSubmit, isSubmitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        <h2 style={{ textAlign: 'center', paddingBottom: '15px' }}>
                            {strings.interestForm.personalInfo}
                        </h2>

                        <Field name="name">
                            {({ field }) => (
                                <div>
                                    <div className="popup-sign-item">
                                        <label htmlFor="name">{strings.interestForm.fields.fullName} {<ErrorMessage name="name" component="span" className="text-danger" />}</label>
                                        <input {...field} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                    </div>
                                </div>
                            )}
                        </Field>

                        <Field name="email">
                            {({ field }) => (
                                <div>
                                    <div className="popup-sign-item">
                                        <label htmlFor="email">{strings.interestForm.fields.email} {<ErrorMessage name="email" component="span" className="text-danger" />}</label>
                                        <input {...field} type="email" className="form-value-color" id="asif-pl" placeholder="" />
                                    </div>
                                </div>
                            )}
                        </Field>

                        <div className="row g-2">
                            <div className="col-4">
                                <Field name="areaCode">
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label htmlFor="name">{strings.interestForm.fields.areaCode} {<ErrorMessage name="areaCode" component="span" className="text-danger" />}</label>
                                            <div className="popup-pass-show">
                                                <input {...field} type="text" name="areaCode" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-8">
                                <Field name="phone">
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label htmlFor="phpne">{strings.interestForm.fields.phone} {<ErrorMessage name="phone" component="span" className="text-danger" />}</label>
                                            <div className="popup-pass-show">
                                                <input {...field} type="text" name="phone" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div>

                        <Field name="country"
                            initialValue={1}>
                            {({ field }) => (
                                <div className="popup-sign-item">
                                    <label>{strings.interestForm.fields.country}</label>
                                    <select {...field}>
                                        {countries.map(country =>
                                            (<option key={"country" + country.id} value={country.id}>{country.name}</option>)
                                        )}
                                    </select>
                                </div>
                            )}
                        </Field>

                        {page === 'sell' &&
                            <Fragment>
                                <h2 style={{ textAlign: 'center', paddingBottom: '15px' }}>
                                    {strings.interestForm.propertyInfo}
                                </h2>

                                <Field name="propertyName">
                                    {({ field }) => (
                                        <div>
                                            <div className="popup-sign-item">
                                                <label htmlFor="propertyName">{strings.interestForm.fields.propertyName} {<ErrorMessage name="propertyName" component="div" className="text-danger" />}</label>
                                                <input {...field} type="text" name="propertyName" className="form-value-color" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>

                                <Field name="city">
                                    {({ field }) => (
                                        <div>
                                            <div className="popup-sign-item">
                                                <label htmlFor="city">{strings.interestForm.fields.city} {<ErrorMessage name="city" component="span" className="text-danger" />}</label>
                                                <input {...field} type="text" name="city" className="form-value-color" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>

                                <div className="row g-2">
                                    <div className="col-4">
                                        <Field name="postCode">
                                            {({ field }) => (
                                                <div className="popup-sign-item">
                                                    <label htmlFor="postCode">{strings.interestForm.fields.postCode} {<ErrorMessage name="postCode" component="span" className="text-danger" />}</label>
                                                    <div className="popup-pass-show">
                                                        <input {...field} type="text" name="postCode" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col-8">
                                        <Field name="street">
                                            {({ field }) => (
                                                <div className="popup-sign-item">
                                                    <label htmlFor="street">{strings.interestForm.fields.street} {<ErrorMessage name="street" component="span" className="text-danger" />}</label>
                                                    <div className="popup-pass-show">
                                                        <input {...field} type="text" name="street" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <Field name="propertyCountry"
                                    initialValue={1}>
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label>{strings.interestForm.fields.country}</label>
                                            <select {...field}>
                                                {countries.map(country =>
                                                    (<option key={"country" + country.id} value={country.id}>{country.name}</option>)
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </Field>

                                <Field name="price">
                                    {({ field }) => (
                                        <div>
                                            <div className="popup-sign-item">
                                                <label htmlFor="price">{strings.interestForm.fields.askingPrice} {<ErrorMessage name="price" component="span" className="text-danger" />}</label>
                                                <input {...field} type="text" name="price" className="form-value-color" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>

                                <Field name="morgage" initialValue={'no'}>
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label>{strings.interestForm.fields.morgage}</label>
                                            <select {...field}>
                                                <option value={'yes'}>{strings.interestForm.morgageOptions.yes}</option>
                                                <option value={'no'}>{strings.interestForm.morgageOptions.no}</option>
                                            </select>
                                        </div>
                                    )}
                                </Field>

                                <Field name="listed" initialValue={'no'}>
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label>{strings.interestForm.fields.listed}</label>
                                            <select {...field}>
                                                <option value={'yes'}>{strings.interestForm.morgageOptions.yes}</option>
                                                <option value={'no'}>{strings.interestForm.morgageOptions.no}</option>
                                            </select>
                                        </div>
                                    )}
                                </Field>

                                <Field name="saleType" initialValue={'all'}>
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label>{strings.interestForm.fields.saleType}</label>
                                            <select {...field}>
                                                <option value={'part'}>{strings.interestForm.saleTypeOptions.part}</option>
                                                <option value={'all'}>{strings.interestForm.saleTypeOptions.all}</option>
                                            </select>
                                        </div>
                                    )}
                                </Field>

                                {values.saleType === 'part' &&
                                    <Field name="percentage">
                                        {({ field }) => (
                                            <div>
                                                <div className="popup-sign-item">
                                                    <label htmlFor="percentage">{strings.interestForm.fields.percentage} {<ErrorMessage name="percentage" component="span" className="text-danger" />}</label>
                                                    <input {...field} type="text" name="percentage" className="form-value-color" id="asif-pl" placeholder="" />
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                }

                            </Fragment>
                        }

                        <div className="popup-sign-in-submite wcl-btn">
                            <button type="submit" disabled={isSubmitting}>{strings.interestForm.buttons.apply}</button>
                        </div>
                    </form> 
                )}
            </Formik>
        </Fragment>
    );
};