import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

export const NoNFTsNotification: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="noNftsNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg} 
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#noNftsNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3>{strings.notifications.noNfts.title}</h3>
                        <p>{strings.notifications.noNfts.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
