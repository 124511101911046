/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

interface WalletUnauthorizedNotificationProps {
    retry: () => void;
    name: string;
}

export const WalletUnauthorizedNotification: React.FC<WalletUnauthorizedNotificationProps> = ({ retry, name }) => {
    function capitalizeFirstLetter(string: string | undefined): string {
        if (!string) {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="walletUnauthorizedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#walletUnauthorizedNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>{strings.property.notifications.walletUnauthorizedNotification.title}</h3>
                        <p>{strings.property.notifications.walletUnauthorizedNotification.message1} {capitalizeFirstLetter(name)} {strings.property.notifications.walletUnauthorizedNotification.message2}</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a onClick={retry} style={{ cursor: 'pointer' }}>
                        {strings.property.notifications.generalErrorNotification.retry}
                    </a>
                </div>
            </div>
        </div>
    );
};