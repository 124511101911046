import React from 'react';
import { useStrings } from '../../../contexts/StringsContext';

export const FeeSuccessModal = () => {
    const strings = useStrings();

    return (
        <div className="modal fade" id="feeSuccessModal" tabIndex={-1} aria-labelledby="feeSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{ position: 'relative', top: 0, right: 0 }}>
                        <h5 className="modal-title" id="feeSuccessModalLabel">{strings.listingFee.componsents.FeeSuccessModal.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="text-center">
                            <h2 className='my-2'>{strings.listingFee.componsents.FeeSuccessModal.subTitle}</h2>
                            <p className='mt-3 mb-0'>
                                {strings.listingFee.componsents.FeeSuccessModal.message1}<br />
                                {strings.listingFee.componsents.FeeSuccessModal.message2}
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">{strings.listingFee.componsents.FeeSuccessModal.closeBtn}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};