import React from "react";
import {useStrings} from "../../../contexts/StringsContext";

export const AccessDeniedNotification: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="accessDeniedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img 
                            src={strings.property.notifications.generalErrorNotification.redAlertImg} 
                            className="img-fluid" 
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#accessDeniedNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3>{strings.notifications.accessDenied.title}</h3>
                        <p>{strings.notifications.accessDenied.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
