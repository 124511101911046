import React, { useEffect } from "react";
import { API_URL } from "../../config";
import { useParams } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";
import { useStrings } from "../../contexts/StringsContext";

interface ConfirmEmailProps { }

export const ConfirmEmail: React.FC<ConfirmEmailProps> = () => {
    const { code } = useParams<{ code: string }>();
    const strings = useStrings();

    useEffect(() => {
        axiosInstance.post(API_URL + "auth/confirmEmail/" + code)
            .then((response) => {
                console.log("Email confirmed successfully:", response.data);
            })
            .catch((error) => {
                console.error("Error confirming email:", error);
            });
    }, [code]);

    return (
        <div className="container">
            <HelmetProvider>
                <Helmet>
                    <title>{strings.meta.title}</title>
                    <meta
                        name="description"
                        content={strings.meta.description}
                    />
                    <meta property="og:title" content={strings.meta.ogTitle} />
                    <meta
                        property="og:image"
                        content={strings.meta.ogImage}
                    />
                </Helmet>
            </HelmetProvider>
            <div className="test-street-area">
                <div className="alert alert-success" role="alert">
                    {strings.confirmEmail.successMessage}
                </div>
            </div>
        </div>
    );
};