import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

export const FeePendingWarning: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="feePendingWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#feePendingWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>{strings.notifications.feePending.title}</h3>
                        <p>{strings.notifications.message.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};