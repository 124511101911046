import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useStrings } from '../../contexts/StringsContext';

interface RegisterProps { }

interface RegisterFormValues {
    name: string;
    email: string;
    password: string;
    repeatPassword: string;
}

export const Register: React.FC<RegisterProps> = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);
    const strings = useStrings();

    const onSubmit = async (values: RegisterFormValues) => {
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        fetch(API_URL + 'auth/register', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((values) => {
                if (values?.status !== 'ok') {
                    setErrors(values);
                } else {
                    setErrors([]);
                    setSuccess(true);
                }
            });
    };

    const validateEmail = (email: string): boolean => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    };

    return (
        <div className="wlc-bg1">
            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">

                        <div className="sign-in-popup-form">
                            <div className="popup-form-title">
                                <h2>{strings.register.form.title}</h2>
                                <p style={{ textAlign: 'center' }}>{strings.register.form.description}</p>
                            </div>
                            {errors.length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    {errors.map((error) => (
                                        <span key={'error' + error}>{error}</span>
                                    ))}
                                </div>
                            )}
                            {success && (
                                <div className="alert alert-success" role="alert">
                                    {strings.register.form.alerts.successMessage}
                                </div>
                            )}
                            <Formik
                                initialValues={{ name: '', email: '', password: '', repeatPassword: '' }}
                                validate={(values: RegisterFormValues) => {
                                    const errors: Partial<RegisterFormValues> = {};
                                    if (!values.name) {
                                        errors.name = 'Required';
                                    }
                                    if (!values.password) {
                                        errors.password = 'Required';
                                    }
                                    if (!values.email) {
                                        errors.email = 'Required';
                                    } else {
                                        if (!validateEmail(values.email)) {
                                            errors.email = 'Invalid e-mail';
                                        }
                                    }
                                    if (!values.repeatPassword) {
                                        errors.repeatPassword = 'Required';
                                    } else if (values.repeatPassword !== values.password) {
                                        errors.repeatPassword = 'Passwords must match';
                                    }
                                    return errors;
                                }}
                                onSubmit={onSubmit} >
                                {({ handleSubmit, isSubmitting, values, handleChange, errors, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="name">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="name">
                                                            {strings.register.form.labels.name} {<ErrorMessage name="name" component="span" className='text-danger' />}
                                                        </label>
                                                        <input {...field} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="email">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="name">
                                                            {strings.register.form.labels.email} {<ErrorMessage name="email" component="span" className='text-danger' />}
                                                        </label>
                                                        <input {...field} type="email" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="password">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="password">
                                                            {strings.register.form.labels.password} {<ErrorMessage name="password" component="span" className='text-danger' />}
                                                        </label>
                                                        <input {...field} type="password" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="repeatPassword">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="repeatPassword">
                                                            {strings.register.form.labels.repeatPassword} {<ErrorMessage name="repeatPassword" component="span" className='text-danger' />}
                                                        </label>
                                                        <input {...field} type="password" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <div>
                                            <div className="popup-sign-item">
                                                <p>{strings.register.form.helperText}</p>
                                            </div>
                                        </div>

                                        <div className="popup-sign-in-submite wcl-btn">
                                            <button type="submit" disabled={isSubmitting}>
                                                {strings.register.form.buttons.submit}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};