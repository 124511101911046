import React from "react";
import { Link } from "react-router-dom";
import { useStrings } from "../../../contexts/StringsContext";

interface UnverifiedWarningProps {
    type: string;
}

export const UnverifiedWarning: React.FC<UnverifiedWarningProps> = ({ type }) => {
    const strings = useStrings();
    const text: string =
        type === 'pledge'
            ? strings.property.notifications.unverifiedWarning.messages.pledge
            : strings.property.notifications.unverifiedWarning.messages.purchase;

    return (
        <div className="alart-s" id="unverifiedWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            style={{ cursor: 'pointer' }}
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#unverifiedWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>{strings.property.notifications.unverifiedWarning.title}</h3>
                        <p>
                            {text} <Link to="/verify">{strings.property.notifications.unverifiedWarning.verifyLinkText}</Link>
                        </p>
                    </div>
                </div>
                <div className="bottom-alart">{/* Additional content for the bottom if needed */}</div>
            </div>
        </div>
    );
};