import React from "react";
import { Link } from "react-router-dom";
import { useStrings } from "../../contexts/StringsContext";

export const Footer: React.FC = () => {
    const currentYear: number = new Date().getFullYear();
    const strings = useStrings();

    const formattedText = strings.footer.copyrightText
        .replace("{{year}}", currentYear.toString())
        .replace("{{companyName}}", strings.footer.companyName)
        .replace("{{companyOnBehalf}}", strings.footer.companyOnBehalf);

    return (
        <footer className="d-flex flex-column justify-content-between">
            <div className="container">
                <div className="footer-items">
                    <p className="copyright d-lg-none d-block mb-lg-2 mb-4">
                        {formattedText}
                    </p>
                    <div className="footer-content1">
                        <Link to="/">
                            <img src={strings.footer.logoSrc} className="img-fluid" alt={strings.footer.logoAltText} width="105" height="36" />
                        </Link>
                    </div>
                    <p className="copyright d-none d-lg-inline-block">
                        {formattedText}
                    </p>
                    <div className="footer-content2 project">
                        <ul className="d-flex align-items-center gap-sm-3 gap-2 justify-content-end">
                            <li>
                                <a href={strings.footer.facebookLink} title="Facebook" target="_blank" rel="noopener noreferrer">
                                    <img src={strings.footer.facebookIconSrc} className="img-fluid" alt={strings.footer.facebookAltText} width="24" height="24" />
                                </a>
                            </li>
                            <li>
                                <a href={strings.footer.twitterLink} title="Twitter" target="_blank" rel="noopener noreferrer">
                                    <img src={strings.footer.twitterIconSrc} className="img-fluid" alt={strings.footer.twitterAltText} width="24" height="24" />
                                </a>
                            </li>
                            <li>
                                <a href={strings.footer.youtubeLink} title="YouTube" target="_blank" rel="noopener noreferrer">
                                    <img src={strings.footer.youtubeIconSrc} className="img-fluid" alt={strings.footer.youtubeAltText} width="30" height="30" style={{ mixBlendMode: "multiply" }} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="support">
                        <a href={strings.footer.mailTo}>
                            <i className="fa-solid fa-headset"></i>
                            <span>{strings.footer.needHelpText}</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};