/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

interface InputsExhaustedNotificationProps {
    retry: () => void;
}

export const InputsExhaustedNotification: React.FC<InputsExhaustedNotificationProps> = ({ retry }) => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="inputsExhaustedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src={strings.property.notifications.generalErrorNotification.redAlertImg}
                            className="img-fluid"
                            alt={strings.property.notifications.generalErrorNotification.redAlertImgAlt}
                            onClick={() => {
                                $('#inputsExhaustedNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>{strings.property.notifications.inputsExhaustedNotification.title}</h3>
                        <p>{strings.property.notifications.inputsExhaustedNotification.message}</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a onClick={retry}>
                        {strings.property.notifications.generalErrorNotification.retry}
                    </a>
                </div>
            </div>
        </div>
    );
};