const blockfrostApiKey = {
    0: "testnet3w5VCCksZStcLIsoiQ4b1tlpi1b7xyYg", // testnet
    1: "mainnetoTJGpOqxk1wrNQAV0i3quHQn2VVwS7DP" // mainnet
}

//export const API_URL = 'http://localhost/realestate/public/api/';
export const API_URL = 'https://marketplace.swayestates.com/api/';

export const WHITELABEL_NAME = 'sway';

export default blockfrostApiKey;