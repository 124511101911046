import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { Note } from '../../../models/Note';
import { useStrings } from '../../../contexts/StringsContext';

interface MyNoteListingProps {
    key: string;
    note: Note;
    type: 'bronze' | 'gold' | 'silver' | 'platinum' | string;
}

export const MyNoteListing: React.FC<MyNoteListingProps> = ({note}: MyNoteListingProps) => {
    const link: string = '/note/' + note.id;
    const videoUrl: string = getVideoFileByType(note.type);
    const strings = useStrings();

    function getVideoFileByType(type: MyNoteListingProps['type']): string | null {
        switch (type) {
            case 'bronze':
                return strings.myNoteListing.vdUrl.vdUrlBronze;
            case 'gold':
                return strings.myNoteListing.vdUrl.vdUrlGold;
            case 'silver':
                return strings.myNoteListing.vdUrl.vdUrlSilver;
            case 'platinum':
                return strings.myNoteListing.vdUrl.vdUrlPlatinum;
            default:
                return null;
        }
    };

    return (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-lg-5 mb-4">
            <Link to={link}>
                <div className="noteItem project-item">
                    <div className="project-img">
                        <ReactPlayer
                            url={videoUrl ?? ''}
                            className="img-fluid"
                            width="100%" height="auto"
                            playing muted loop playsinline
                        />
                    </div>
                    <div className="project-content-box" style={{ top: '65%' }}>
                        <div className="project-content-item">
                            <h3>{note.name}</h3>
                            <p className='conversion-date'>
                                {strings.myNoteListing.conversionDate}{' '}
                                <span style={{ fontWeight: 'bold' }}>{new Date(note.conversion_time).toLocaleDateString()}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};