/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { API_URL } from "../../config";
import { useParams } from "react-router";
import { BuyOtherModal } from "./componsents/BuyOtherModal";
import { UnauthenticatedWarning } from "./componsents/UnauthenticatedWarning";
import { UnverifiedWarning } from "./componsents/UnverifiedWarning";
import { ProcessingPaymentModal } from "./componsents/ProcessingPaymentModal";
import { PaymentCompleteModal } from "./componsents/PaymentCompleteModal";
import { PledgeFiatModal } from "./componsents/PledgeFiatModal";
import { NoWalletNotification } from "./componsents/NoWalletNotification";
import { InputsExhaustedNotification } from "./componsents/InputsExhaustedNotification";
import { WalletUnauthorizedNotification } from "./componsents/WalletUnauthorizedNotification";
import { WrongNetworkNotification } from "./componsents/WrongNetworkNotification";
import { PaymentCancelledNotification } from "./componsents/PaymentCancelledNotification";
import { GeneralErrorNotification } from "./componsents/GeneralErrorNottification";
import { Spinner } from "../common/Spinner";
import { PaymentPendingNotification } from "./componsents/PaymentPendingNotification";
import { CardanoWallet } from "../../helpers/CardanoWallet";
import { UnauthenticatedModal } from "../login/UnauthenticatedModal";
import { SaleCompleteModal } from "../nft/components/SaleCompleteModal";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BuyWithFiatModal } from "./componsents/BuyWithFiatModal";
import { HelioCheckout } from '@heliofi/checkout-react';
import HelioErrorNotification from './componsents/HelioErrorNotification';
import HelioSuccessModal from './componsents/HelioSuccessModal';
import HelioPendingNotification from './componsents/HelioPendingNotification';
import HelioCancelNotification from './componsents/HelioCancelNotification';
import axiosInstance from '../../api/axiosConfig';
import { formatCurrencyWithoutSymbol } from '../../helpers/formattingHelper';
import { SamplePropertyNotification } from './componsents/SamplePropertyNotification';
import axios from "axios";
import { useStrings } from '../../contexts/StringsContext';

export const Property: React.FC = () => {

    let { propertyId } = useParams();
    let paymentInterval: NodeJS.Timeout | null = null;
    let paymentIntervalSecondary: NodeJS.Timeout | null = null;
    const [property, setProperty] = useState<any>(null);
    const [price, setPrice] = useState<number>(0);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [paymentStatus, setPaymentStatus] = useState<string>('waiting');
    const [qrCode, setQrCode] = useState<string>('');
    const [expiryTime, setExpiryTime] = useState<string>('');
    const [descriptionExtended, setDescriptionExtended] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastWallet, setLastWallet] = useState<string>('');
    const [lastTransactionType, setLastTransactionType] = useState<string>('');
    const [lastSecondaryTransaction, setLastSecondaryTransaction] = useState<any>(null);
    const [currentPaymentId, setCurrentPaymentId] = useState<number>(0);
    const [likes, setLikes] = useState<number>(0);
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [image, setImage] = useState<string>('');
    const strings = useStrings();

    useEffect(() => {
        return () => {
            document.body.classList.remove('test-ppi-bg');
            document?.querySelectorAll('header')[0]?.classList?.remove('test-street-page');
        };
    }, []);

    const startPayment = async (type: string): Promise<void> => {
        getPaymentInfo(() => {
            setPaymentStatus('pending');
        });
    };

    const getWalletPayment = async (paymentAddress: string, paymentSum: number, walletName: string): Promise<any | null> => {

        $('.notification').hide();
        setLastWallet(walletName);

        const wallet = new CardanoWallet(walletName);
        const isAvailable = wallet.checkForWallet();

        if (!isAvailable) {
            setLoading(false);
            $('#noWalletNotification').fadeIn();
            return null;
        }

        const isEnabled = await wallet.enableWallet();

        if (isEnabled !== true && (isEnabled.status !== true)) {
            setLoading(false);
            $('#walletUnauthorizedNotification').fadeIn();
            return null;
        }

        const networkId = await wallet.getNetworkId();

        if (networkId !== 1) {
            setLoading(false);
            $('#wrongNetworkNotification').fadeIn();
            return null;
        }

        return await wallet.buildSendADATransaction(
            paymentAddress,
            paymentSum
        );
    };

    const typhonPayment = async (): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }

        const walletName: string = 'typhon';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const flintPayment = async (): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'flint';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const eternlPayment = async (): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'eternl';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const yoroiPayment = async (): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'yoroi';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const namiPayment = async (): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'nami';
        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            //const paymentAddress = 'addr1qx9klw2nxfgeru6lw2jtrajgfax7fcjvrjrrejjrmfp5r5ulrwrra6afl9f4x8rgay74p2wjgd82nr0sq25lsfj5zuysjn5sz7';
            //const paymentSum = 52.19;
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const handlePaymentResponse = (payment: any): void => {

        setLoading(false);

        if (payment?.code) {
            if (payment?.code === 2) {
                cancelPayment();
                $('#paymentCancelledNotification').fadeIn();
            }
        } else if (payment?.indexOf('Transaction error') > -1) {
            cancelPayment();
            if (payment.indexOf('Not enough ADA') > -1 || payment.indexOf('Insufficient') > -1 || payment.indexOf('Exhausted') > -1) {
                $('#inputsExhaustedNotification').fadeIn();//Not enough funds (probably)
            } else {
                $('#generalErrorNotification').fadeIn();//General error?
            }

        } else {
            setPaymentStatus('processing');
            $('#processingPayment').modal('show');
        }
    };

    const getPaymentInfo = async (success: (address: string, price: number) => void): Promise<boolean> => {

        $('.alart-s').hide();
        setLastTransactionType('primary');

        if (!localStorage.getItem('token')) {
            setLoading(false);
            $('#unauthenticatedModal').modal('show');
            //$('#unauthenticatedWarning').fadeIn();
            setPaymentStatus('unauthorized');
            return false;
        }

        try {
            const response = await axiosInstance.get(`payment/start/${propertyId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
            });

            const res = response.data;

            setCurrentPaymentId(res.id);
            setPrice(res.price);
            setWalletAddress(res.address);
            setQrCode('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=web+cardano:' + res.address + '?amount=' + res.price);
            setExpiryTime(new Date(res.expires).toISOString());
            startTimer(60 * 60, $('#timeLeft'));
            checkPayment(res.id);
            success(res.address, res.price);
            return Promise.resolve(true);
        } catch (err) {
            setLoading(false);

            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    $('#unauthenticatedModal').modal('show');
                    setPaymentStatus('unauthorized');
                } else if (err.response?.status === 403) {
                    $('#unverifiedWarning').fadeIn();
                    setPaymentStatus('unverified');
                }
            }

            return false;
        }
    };

    const getPaymentInfoSecondary = async (saleId: string, success: (address: string, price: number) => void): Promise<boolean> => {

        $('.alart-s').hide();
        setLastTransactionType('secondary');

        if (!localStorage.getItem('token')) {
            setLoading(false);
            $('#unauthenticatedModal').modal('show');
            //$('#unauthenticatedWarning').fadeIn();
            setPaymentStatus('unauthorized');
            return false;
        }

        try {
            const response = await axiosInstance.get(`payment/secondayBuy/start/${saleId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
            });

            const res = response.data;

            if (res?.status === 'error') {
                setLoading(false);
                $('#paymentPendingWarning').fadeIn();
            } else {
                setCurrentPaymentId(res.id);
                setPrice(res.price);
                setWalletAddress(res.address);
                setQrCode('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=web+cardano:' + res.address + '?amount=' + res.price);
                setExpiryTime(new Date(res.expires).toISOString());
                startTimer(60 * 60, $('#timeLeft'));
                checkPaymentSecondary(res.id);
                success(res.address, res.price);
            }
            return true;
        } catch (err) {
            setLoading(false);

            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    $('#unauthenticatedModal').modal('show');
                    setPaymentStatus('unauthorized');
                } else if (err.response?.status === 403) {
                    $('#unverifiedWarning').fadeIn();
                    setPaymentStatus('unverified');
                }
            }

            return false;
        }
    };

    // const fiatPayment = () => { };

    const checkPayment = (paymentId: number): void => {
        paymentInterval = setInterval(() => {
            axiosInstance.get(`payment/status/${paymentId}?token=${localStorage.getItem("token")}`)
                .then(response => {
                    const res = response.data;
                    if (res.status === 'success') {
                        setPaymentStatus('success');
                        $('#processingPayment').modal('hide');
                        $('#saleComplete').modal('show');
                        if (paymentInterval !== null) {
                            clearInterval(paymentInterval);
                        }
                        updateData();
                    }
                });
        }, 5000);
    };

    const checkPaymentSecondary = (paymentId: number): void => {
        paymentIntervalSecondary = setInterval(() => {
            axiosInstance.get(`payment/secondayBuy/status/${paymentId}?token=${localStorage.getItem("token")}`)
                .then(response => {
                    const res = response.data;
                    if (res.status === 'success') {
                        setPaymentStatus('success');
                        $('#processingPayment').modal('hide');
                        $('#saleComplete').modal('show');
                        if (paymentIntervalSecondary !== null) {
                            clearInterval(paymentIntervalSecondary);
                        }
                        updateData();
                    }
                });
        }, 5000);
    };

    const startTimer = (duration: number, display: any): void => {
        let timer = duration;
        let minutes, seconds;

        const interval = setInterval(() => {
            minutes = parseInt(String(timer / 60), 10);
            seconds = parseInt(String(timer % 60), 10);

            minutes = minutes < 10 ? "0" + minutes : String(minutes);
            seconds = seconds < 10 ? "0" + seconds : String(seconds);

            display.textContent = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    };

    useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLikeClick = (): void => {
        axiosInstance.get(`property/${propertyId}/like?token=${localStorage.getItem("token")}`)
            .then(response => {
                const res = response.data;
                setLikes(res.likes);
                setIsLiked(!isLiked);
            })
            .catch(e => {
                console.log('Error', e);
                $('#unauthenticatedModal').modal('show');
            });
    };

    const updateData = (): void => {
        let url = `property/${propertyId}`;
        if (localStorage.getItem("token")) {
            url = `${url}?token=${localStorage.getItem("token")}`;
        }
        axiosInstance.get(url)
            .then(response => {
                const res = response.data;
                setProperty(res.property);
                setLikes(res.property.likes_count);
                setIsLiked(res.property?.liked || false);
                setImage(res.property?.images[0]?.image);
            });
    };

    const buySecondaryNami = async (id: any): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'nami';
        setLastSecondaryTransaction(id);
        setLoading(true);
        await getPaymentInfoSecondary(id, async (paymentAddress, paymentSum) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                handlePaymentResponse(payment);
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const buySecondaryEternl = async (id: any): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'eternl';
        setLastSecondaryTransaction(id);
        setLoading(true);
        await getPaymentInfoSecondary(id, async (paymentAddress, paymentSum) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                handlePaymentResponse(payment);
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const buySecondaryFlint = async (id: any): Promise<void> => {

        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        const walletName: string = 'flint';
        setLastSecondaryTransaction(id);
        setLoading(true);
        await getPaymentInfoSecondary(id, async (paymentAddress, paymentSum) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        });
    };

    const handleWalletException = (e: any): void => {

        console.log(e);
        setLoading(false);

        cancelPayment();

        if ((e?.message?.indexOf('undefined is not an object (evaluating \'this.Nami.isEnabled\')') > -1 || typeof e === 'undefined') && typeof e !== 'string') {
            $('#noWalletNotification').fadeIn();
        } else if (e?.message?.indexOf('Insufficient') > -1 || (typeof e === 'string' && e?.indexOf('Insufficient') > -1) || (typeof e === 'string' && e?.indexOf('Exhausted') > -1)) {
            $('#inputsExhaustedNotification').fadeIn();
        } else {
            if (e?.code === -3) { //Unauthorized
                $('#walletUnauthorizedNotification').fadeIn();
            } else if (e?.code === -15) { //Wrong network
                $('#wrongNetworkNotification').fadeIn();
            } else if (e?.code === 2) { //Declined
                $('#paymentCancelledNotification').fadeIn();
            } else {
                $('#generalErrorNotification').fadeIn();
            }
        }
    };

    const buySecondaryOther = async (id: any): Promise<void> => {
        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
            return;
        }
        getPaymentInfoSecondary(id, () => {
            setPaymentStatus('pending');
        });
    };

    const handleBuy = () => {
        if (property.sample) {
            $('#samplePropertyNotification').fadeIn();
        } else {
            startPayment('sale');
        }
    };

    const retryPayment = async (): Promise<void> => {
        if (lastTransactionType === 'primary') {
            if (lastWallet === 'nami') {
                return namiPayment();
            } else if (lastWallet === 'yoroi') {
                return yoroiPayment();
            } else if (lastWallet === 'eternl') {
                return eternlPayment()
            } else if (lastWallet === 'flint') {
                return flintPayment();
            } else if (lastWallet === 'typhon') {
                return typhonPayment()
            }
        } else {
            if (lastWallet === 'nami') {
                return buySecondaryNami(lastSecondaryTransaction);
            } else if (lastWallet === 'eternl') {
                return buySecondaryEternl(lastSecondaryTransaction)
            } else if (lastWallet === 'flint') {
                return buySecondaryFlint(lastSecondaryTransaction);
            }
        }
    };

    const cancelPayment = (): void => {
        if (paymentInterval) {
            clearInterval(paymentInterval);
        }

        if (paymentIntervalSecondary) {
            clearInterval(paymentIntervalSecondary);
        }
        axiosInstance.get(`cancel/payment/${currentPaymentId}`)
            .then(response => response.data)
            .then(res => {
            });

        axiosInstance.get(`cancel/purchase/${currentPaymentId}`)
            .then(response => response.data)
            .then(res => {
            });
    };

    const buyWithFiat = (): void => {
        $('#buyFiat').modal('show');
    }

    if (!property) {
        return (<div className={'text-center'}><Spinner /></div>);
    }

    const shortDescription = property?.description.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|").splice(0, 2).join(" ");

    const priceFiat = formatCurrencyWithoutSymbol(property.price, property.currency);
    const priceAda = formatCurrencyWithoutSymbol(property.priceAda, { name: 'ADA', symbol: '₳' });

    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(property.address)}`;
    const shareUrl = encodeURIComponent(window.location.href);
    const title = encodeURIComponent(property.address);
    const imageUrl = encodeURIComponent(property.display_picture);
    const handleFacebookShare = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        try {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${title}&picture=${imageUrl}`, '_blank');
        } catch (error) {
            console.error('Error sharing on Facebook:', error);
        }
    };

    const HelioCheckoutComponent = () => {

        const [tokenExists, setTokenExists] = useState(localStorage.getItem('token') !== null);

        const helioSuccess = (): void => {
            $('#helioSuccessModal').modal('show');
        };

        const helioError = (): void => {
            $('#helioErrorNotification').fadeIn();
        };

        const helioPending = (): void => {
            $('#paymentPendingWarning').fadeIn();
        };

        const helioCancel = () => {
            $('#helioCancelNotification').fadeIn();
        };

        const helioConfig: any = useMemo(() => {
            const validAmount = parseFloat(priceFiat.replace(/[^0-9.-]+/g, '')).toFixed(2);
            return {
                paylinkId: "65f35ff66b5e964a16b0e2c8",
                amount: String(validAmount),
                display: "button",
                onSuccess: helioSuccess,
                onError: helioError,
                onPending: helioPending,
                onCancel: helioCancel,
                onStartPayment: () => console.log("Starting payment"),
                paymentType: "PAYLINK",
                network: "test",
                additionalJSON: { property: propertyId }
            };
        }, []);

        const handleClick = () => {
            if (!tokenExists) {
                setLoading(false);
                $('#unauthenticatedModal').modal('show');
                //$('#unauthenticatedWarning').fadeIn();
                setPaymentStatus('unauthorized');
                return false;
            }
        };

        return (
            <>
                {tokenExists ? (
                    <HelioCheckout config={helioConfig} />
                ) : (
                    <button onClick={handleClick} className='hel-button'>
                        <span className='hel-text-small'>{strings.property.payHelio}</span>
                    </button>
                )}
            </>
        );
    };

    return (
        <Fragment>
            {!loading && (
                <HelmetProvider>
                    <Helmet>
                        <title>{`Sway Estates - ${property.title}`}</title>
                        <meta name="description" content={property.address} />
                        <meta property="og:title" content={property.title} />
                        <meta property="og:image" content={property.display_picture} />
                        <meta property="og:description" content={property.description} />
                    </Helmet>
                </HelmetProvider>
            )}
            {loading && <Spinner />}
            <div className="wlc-bg1">
                <div className="welcome-page-area">
                    <div className="container">
                        <div className="test-street-area properties">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="test-street-left-item">
                                        <div className="test-light-box-img">
                                            <img src={image} className="img-fluid" alt="" />
                                        </div>
                                        <div className="test-street-left-slider mt-2">
                                            <div className="container-flui pt-1">
                                                <div className="slider-inner">
                                                    <div className="carousel">
                                                        <input
                                                            defaultChecked={true}
                                                            className="carousel__activator"
                                                            id="carousel-slide-activator-1"
                                                            name="carousel"
                                                            type="radio"
                                                        />
                                                        <input
                                                            className="carousel__activator"
                                                            id="carousel-slide-activator-2"
                                                            name="carousel"
                                                            type="radio"
                                                        />
                                                        <input
                                                            className="carousel__activator"
                                                            id="carousel-slide-activator-3"
                                                            name="carousel"
                                                            type="radio"
                                                        />
                                                        <div className="carousel__controls">
                                                            <label
                                                                className="carousel__control carousel__control--forward"
                                                                htmlFor="carousel-slide-activator-2">
                                                                &#10095;
                                                            </label>
                                                        </div>
                                                        <div className="carousel__controls">
                                                            <label
                                                                className="carousel__control carousel__control--backward"
                                                                htmlFor="carousel-slide-activator-1">
                                                                &#10094;
                                                            </label>
                                                            <label
                                                                className="carousel__control carousel__control--forward"
                                                                htmlFor="carousel-slide-activator-3">
                                                                &#10095;
                                                            </label>
                                                        </div>
                                                        <div className="carousel__controls">
                                                            <label
                                                                className="carousel__control carousel__control--backward"
                                                                htmlFor="carousel-slide-activator-2">
                                                                &#10094;
                                                            </label>
                                                        </div>
                                                        <div className="carousel__screen">
                                                            <div className="carousel__track">
                                                                {property.images.map((item: any, key: number) => (
                                                                    <div onClick={() => setImage(item.image)}
                                                                        className="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-4"
                                                                        key={`image-${key}`}>
                                                                        <div className="demo-content">
                                                                            <img src={item.image} alt=""
                                                                                className="img-fluid" />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="test-street-right-item">
                                        <div className="test-street-title">
                                            <h2><span> {property.address}
                                                <br />
                                                <a target="_blank"
                                                    href={"https://www.google.com/maps/search/?api=1&query=" + property.address}
                                                    rel="noreferrer">{strings.property.viewMap}</a></span>
                                            </h2>
                                        </div>
                                        <div className="test-street-nft-icons-area">
                                            <div className="test-street-nft-icons">
                                                <div className="text-nft-icon-content1">
                                                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M39.9672 18C39.9121 13.6619 39.7433 10.4038 39.5714 8.11495C39.3794 5.55844 37.4274 3.6064 34.8708 3.41441C32.0818 3.20494 27.8536 3 21.9858 3C16.118 3 11.8898 3.20494 9.1008 3.4144C6.54428 3.6064 4.59224 5.55844 4.40025 8.11496C4.19078 10.904 3.98584 15.1322 3.98584 21C3.98584 26.8678 4.19078 31.096 4.40024 33.885C4.59224 36.4416 6.54427 38.3936 9.1008 38.5856C11.8898 38.7951 16.118 39 21.9858 39C23.0381 39 24.0378 38.9934 24.9858 38.9814"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M38.3086 22.832C40.0778 24.6896 43.2371 28.2791 45.3595 32.2494C45.7774 33.031 45.7774 33.9676 45.3595 34.7492C43.2371 38.7195 40.0778 42.309 38.3086 44.1666C37.5795 44.9322 36.3917 44.9322 35.6625 44.1666C33.8935 42.309 30.7346 38.7196 28.6118 34.7495C28.1938 33.9677 28.1938 33.0309 28.6118 32.2491C30.7346 28.279 33.8935 24.6896 35.6625 22.832C36.3917 22.0664 37.5795 22.0664 38.3086 22.832Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M28.4858 33.5C28.4858 33.5 34.9858 36.5 36.9858 36.5C38.9858 36.5 45.4858 33.5 45.4858 33.5"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M33.9858 12C33.9858 13.6569 32.6427 15 30.9858 15C29.3289 15 27.9858 13.6569 27.9858 12C27.9858 10.3431 29.3289 9 30.9858 9C32.6427 9 33.9858 10.3431 33.9858 12Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M4.31982 26.4516C10.1732 20.5982 14.0158 17.654 16.2232 16.2092C17.6677 15.2638 19.4219 15.4271 20.7593 16.5188C22.2313 17.7204 24.5472 19.7416 27.9863 23.0581"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="text-nft-icon-content2">
                                                    <h3>{property.nfts}</h3>
                                                    <p>{strings.property.totalNFT}</p>
                                                </div>
                                            </div>
                                            <div className="test-street-nft-icons right-nft-icon">
                                                <div className="text-nft-icon-content1">
                                                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M39.9672 18C39.9121 13.6619 39.7433 10.4038 39.5714 8.11495C39.3794 5.55844 37.4274 3.6064 34.8708 3.41441C32.0818 3.20494 27.8536 3 21.9858 3C16.118 3 11.8898 3.20494 9.1008 3.4144C6.54428 3.6064 4.59224 5.55844 4.40025 8.11496C4.19078 10.904 3.98584 15.1322 3.98584 21C3.98584 26.8678 4.19078 31.096 4.40024 33.885C4.59224 36.4416 6.54427 38.3936 9.1008 38.5856C11.8898 38.7951 16.118 39 21.9858 39C23.0381 39 24.0378 38.9934 24.9858 38.9814"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M38.3086 22.832C40.0778 24.6896 43.2371 28.2791 45.3595 32.2494C45.7774 33.031 45.7774 33.9676 45.3595 34.7492C43.2371 38.7195 40.0778 42.309 38.3086 44.1666C37.5795 44.9322 36.3917 44.9322 35.6625 44.1666C33.8935 42.309 30.7346 38.7196 28.6118 34.7495C28.1938 33.9677 28.1938 33.0309 28.6118 32.2491C30.7346 28.279 33.8935 24.6896 35.6625 22.832C36.3917 22.0664 37.5795 22.0664 38.3086 22.832Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M28.4858 33.5C28.4858 33.5 34.9858 36.5 36.9858 36.5C38.9858 36.5 45.4858 33.5 45.4858 33.5"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M33.9858 12C33.9858 13.6569 32.6427 15 30.9858 15C29.3289 15 27.9858 13.6569 27.9858 12C27.9858 10.3431 29.3289 9 30.9858 9C32.6427 9 33.9858 10.3431 33.9858 12Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M4.31982 26.4516C10.1732 20.5982 14.0158 17.654 16.2232 16.2092C17.6677 15.2638 19.4219 15.4271 20.7593 16.5188C22.2313 17.7204 24.5472 19.7416 27.9863 23.0581"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="text-nft-icon-content2">
                                                    <h3>{property.available}</h3>
                                                    <p>{strings.property.availableNFT}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="test-street-nft-icons-area">
                                            <div className="test-street-nft-icons">
                                                <div className="text-nft-icon-content1">
                                                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M39.9672 18C39.9121 13.6619 39.7433 10.4038 39.5714 8.11495C39.3794 5.55844 37.4274 3.6064 34.8708 3.41441C32.0818 3.20494 27.8536 3 21.9858 3C16.118 3 11.8898 3.20494 9.1008 3.4144C6.54428 3.6064 4.59224 5.55844 4.40025 8.11496C4.19078 10.904 3.98584 15.1322 3.98584 21C3.98584 26.8678 4.19078 31.096 4.40024 33.885C4.59224 36.4416 6.54427 38.3936 9.1008 38.5856C11.8898 38.7951 16.118 39 21.9858 39C23.0381 39 24.0378 38.9934 24.9858 38.9814"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M38.3086 22.832C40.0778 24.6896 43.2371 28.2791 45.3595 32.2494C45.7774 33.031 45.7774 33.9676 45.3595 34.7492C43.2371 38.7195 40.0778 42.309 38.3086 44.1666C37.5795 44.9322 36.3917 44.9322 35.6625 44.1666C33.8935 42.309 30.7346 38.7196 28.6118 34.7495C28.1938 33.9677 28.1938 33.0309 28.6118 32.2491C30.7346 28.279 33.8935 24.6896 35.6625 22.832C36.3917 22.0664 37.5795 22.0664 38.3086 22.832Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M28.4858 33.5C28.4858 33.5 34.9858 36.5 36.9858 36.5C38.9858 36.5 45.4858 33.5 45.4858 33.5"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M33.9858 12C33.9858 13.6569 32.6427 15 30.9858 15C29.3289 15 27.9858 13.6569 27.9858 12C27.9858 10.3431 29.3289 9 30.9858 9C32.6427 9 33.9858 10.3431 33.9858 12Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M4.31982 26.4516C10.1732 20.5982 14.0158 17.654 16.2232 16.2092C17.6677 15.2638 19.4219 15.4271 20.7593 16.5188C22.2313 17.7204 24.5472 19.7416 27.9863 23.0581"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="text-nft-icon-content2">
                                                    <h3>{property.price}</h3>
                                                    <p>{strings.property.priceNFT}</p>
                                                </div>
                                            </div>
                                            <div className="test-street-nft-icons right-nft-icon">
                                                <div className="text-nft-icon-content1">
                                                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M39.9672 18C39.9121 13.6619 39.7433 10.4038 39.5714 8.11495C39.3794 5.55844 37.4274 3.6064 34.8708 3.41441C32.0818 3.20494 27.8536 3 21.9858 3C16.118 3 11.8898 3.20494 9.1008 3.4144C6.54428 3.6064 4.59224 5.55844 4.40025 8.11496C4.19078 10.904 3.98584 15.1322 3.98584 21C3.98584 26.8678 4.19078 31.096 4.40024 33.885C4.59224 36.4416 6.54427 38.3936 9.1008 38.5856C11.8898 38.7951 16.118 39 21.9858 39C23.0381 39 24.0378 38.9934 24.9858 38.9814"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M38.3086 22.832C40.0778 24.6896 43.2371 28.2791 45.3595 32.2494C45.7774 33.031 45.7774 33.9676 45.3595 34.7492C43.2371 38.7195 40.0778 42.309 38.3086 44.1666C37.5795 44.9322 36.3917 44.9322 35.6625 44.1666C33.8935 42.309 30.7346 38.7196 28.6118 34.7495C28.1938 33.9677 28.1938 33.0309 28.6118 32.2491C30.7346 28.279 33.8935 24.6896 35.6625 22.832C36.3917 22.0664 37.5795 22.0664 38.3086 22.832Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M28.4858 33.5C28.4858 33.5 34.9858 36.5 36.9858 36.5C38.9858 36.5 45.4858 33.5 45.4858 33.5"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M33.9858 12C33.9858 13.6569 32.6427 15 30.9858 15C29.3289 15 27.9858 13.6569 27.9858 12C27.9858 10.3431 29.3289 9 30.9858 9C32.6427 9 33.9858 10.3431 33.9858 12Z"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                        <path
                                                            d="M4.31982 26.4516C10.1732 20.5982 14.0158 17.654 16.2232 16.2092C17.6677 15.2638 19.4219 15.4271 20.7593 16.5188C22.2313 17.7204 24.5472 19.7416 27.9863 23.0581"
                                                            stroke="#3C6E71" strokeWidth="3" strokeLinecap="round"
                                                            strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="text-nft-icon-content2">
                                                    <h3>{property.income}</h3>
                                                    <p>{strings.property.incomeMonth}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tsts-pra">
                                            {!descriptionExtended && (
                                                <p>
                                                    {shortDescription}&nbsp;&nbsp;
                                                    <a href="" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDescriptionExtended(!descriptionExtended);
                                                    }}>{strings.property.readMore}</a>
                                                </p>
                                            )}
                                            {descriptionExtended && (
                                                <Fragment>
                                                    {property.description.split('\n').map((item: any, key: number) => {
                                                        return <p key={key}>{item}<br /></p>
                                                    })}
                                                    <p>
                                                        <a href="" onClick={(e) => {
                                                            e.preventDefault();
                                                            setDescriptionExtended(!descriptionExtended);
                                                        }}>{strings.property.showLess}</a>
                                                    </p>
                                                </Fragment>
                                            )}
                                            <div className="test-stast-right-price">
                                                <p style={{ margin: "20px 0 10px 0px" }}>{strings.property.priceNFT}</p>
                                                <ul>
                                                    <li><span>{property.currency.symbol}</span> {priceFiat}</li>
                                                    <li>/ <span>ADA</span> {priceAda}</li>
                                                </ul>
                                            </div>
                                            {property.transaction_type === "sale" && (
                                                <div className="test-stast-right-btn">
                                                    <div className="row">
                                                        <div className="dropdown wallet-selection col-lg-8 mb-3">
                                                            <button className="button btn dropdown-toggle selection-button"
                                                                type="button"
                                                                id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                aria-expanded="false">{strings.property.selectWallet}{" "}<i
                                                                    id="icon" className="fa fa-chevron-down"></i>
                                                            </button>
                                                            <div className="dropdown-menu wallet-dropdown px-2 pb-0"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <ul className="wallet-dropdownbtns">
                                                                    <li>
                                                                        <a className="button btn-nami" onClick={namiPayment}>
                                                                            {" "}{strings.property.buyPropWithNami}{" "}</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="button btn-eternl" onClick={eternlPayment}>
                                                                            {" "}{strings.property.buyPropWithEternl}{" "}</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="button btn-flint" onClick={flintPayment}>
                                                                            {" "}{strings.property.buyPropWithFlint}{" "}</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <ul className="col-lg-4 test-stast-right-btn mb-3">
                                                            <li>
                                                                <a className={"white button btn-other"} onClick={handleBuy}>
                                                                    {" "}{strings.property.buyPropWithOthers}{" "}</a>
                                                            </li>
                                                            <li>
                                                                <a className={"white button btn-other"} onClick={() => {
                                                                    buyWithFiat();
                                                                }}>{" "}{strings.property.buyPropWithFiat}{" "}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row">
                                                        <ul className="col-lg-12 mb-3">
                                                            <li>
                                                                <HelioCheckoutComponent />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            {property.transaction_type === "pledge" && (
                                                <div className="alart-btn-right-item teb-btn-alt-4-none">
                                                    <ul>
                                                        <li><a id="alt-4-1bg" onClick={namiPayment}>{strings.property.pledgeWNami}</a></li>
                                                        <li><a className={"white"} onClick={() => {
                                                            startPayment("pledge");
                                                        }} id="mobile-alt-4-bg">{strings.property.pledgeWOther}</a>
                                                        </li>
                                                        <li><a href="#">{strings.property.pledgeWFiat}</a></li>
                                                    </ul>
                                                </div>
                                            )}
                                            <div className="test-stast-icon">
                                                <ul className="d-flex">
                                                    <li>
                                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${title}&picture=${imageUrl}`}
                                                            onClick={handleFacebookShare} rel="noopener noreferrer">
                                                            <img src={strings.property.sharePropWithFBImg}
                                                                className="img-fluid" alt={strings.property.sharePropWithFBImgAlt}
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={twitterShareUrl} target="_blank" rel="noreferrer">
                                                            <img src={strings.property.sharePropWithTweetImg}
                                                                className="img-fluid" alt={strings.property.sharePropWithTweetImgAlt}
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="heart-like-button mt-4">
                                                <button className={`like-button ${isLiked && "liked"}`}
                                                    onClick={handleLikeClick}>
                                                    <span className="heart"></span>
                                                    <span className="likes-counter">{`Like ${likes}`}</span>
                                                </button>
                                            </div>
                                            <div className="test-view-pdf">
                                                <ul>
                                                    <li><a target="_blank"
                                                        href={
                                                            "https://nftworld.mypinata.cloud/ipfs/" + property.deed}
                                                        rel="noreferrer">{strings.property.viewDeed}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {property.secondary?.length > 0 && (
                                            <div style={{ paddingTop: "10px" }}>
                                                <h3>{strings.property.secondaryMrktTitle}</h3>
                                                {property.secondary.map((offer: any) => {
                                                    const offerPriceFiat = formatCurrencyWithoutSymbol(offer.priceEur, property.currency);
                                                    const offerPriceAda = formatCurrencyWithoutSymbol(offer.price, {
                                                        name: "ADA",
                                                        symbol: "₳",
                                                    });
                                                    return (
                                                        <div
                                                            key={"secondary" + offer.id}
                                                            style={{
                                                                borderTop: "1px solid #DEDEDE",
                                                                paddingTop: "10px",
                                                                marginTop: "10px",
                                                            }}>
                                                            <p style={{ fontFamily: "Circular Std", fontSize: 13 }}>
                                                                {offer.created_at}
                                                            </p>
                                                            <div className="test-stast-right-price">
                                                                <ul>
                                                                    <li><span>ADA</span> {offerPriceAda}</li>
                                                                    <li>/ <span>{property.currency.symbol}</span>{" "}{offerPriceFiat}</li>
                                                                </ul>
                                                            </div>
                                                            <div className="test-stast-right-btn">
                                                                <div className="row">
                                                                    <div className="dropdown wallet-selection col-lg-8 mb-3">
                                                                        <button
                                                                            className="button btn dropdown-toggle selection-button"
                                                                            type="button"
                                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                            aria-expanded="false">{strings.property.selectWallet}{" "}<i
                                                                                id="icon" className="fa fa-chevron-down"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu wallet-dropdown px-2 pb-0"
                                                                            aria-labelledby="dropdownMenuButton">
                                                                            <ul className="wallet-dropdownbtns">
                                                                                <li>
                                                                                    <a className="button btn-nami"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            buySecondaryNami(offer.id);
                                                                                        }}>{" "}{strings.property.buyPropWithNami}{" "}
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a className="button btn-eternl"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            buySecondaryEternl(offer.id);
                                                                                        }}>{" "}
                                                                                        {strings.property.buyPropWithEternl}{" "}
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a className="button btn-flint"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            buySecondaryFlint(offer.id);
                                                                                        }}>{" "}
                                                                                        {strings.property.buyPropWithFlint}{" "}
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <ul className="col-lg-4 test-stast-right-btn mb-3">
                                                                        <li>
                                                                            <a className={"white button btn-other"}
                                                                                onClick={() => {
                                                                                    buySecondaryOther(offer.id);
                                                                                }}>{" "}
                                                                                {strings.property.buyPropWithOthers}{" "}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                paymentStatus === "pending" && (
                    <BuyOtherModal
                        qrCode={qrCode}
                        wallet={walletAddress}
                        price={String(price)}
                        expiry={Number(expiryTime)}
                    />
                )
            }
            <UnauthenticatedWarning type={""} />
            <UnverifiedWarning type={""} />
            <ProcessingPaymentModal />
            <PaymentCompleteModal type={"sale"} />
            <PledgeFiatModal />
            <NoWalletNotification />
            <PaymentPendingNotification />
            <InputsExhaustedNotification retry={retryPayment} />
            <WalletUnauthorizedNotification retry={retryPayment} name={lastWallet} />
            <WrongNetworkNotification retry={retryPayment} />
            <PaymentCancelledNotification retry={retryPayment} />
            <GeneralErrorNotification retry={retryPayment} />
            <HelioErrorNotification />
            <HelioSuccessModal />
            <HelioPendingNotification />
            <UnauthenticatedModal />
            <HelioCancelNotification />
            <SaleCompleteModal type={"other"} />
            <BuyWithFiatModal />
            <SamplePropertyNotification />
        </Fragment >
    );

};