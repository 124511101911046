import React from "react";
import { useStrings } from "../../../contexts/StringsContext";

export const UnlistedNotification: React.FC = () => {
    const strings = useStrings();

    return (
        <div className="alart-s notification" id="unlistedNotification" style={{ display: 'none' }}>
            <div className="alart-area" style={{ backgroundColor: '#25a552' }}>
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.swayestates.com/img/alart-black.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#unlistedNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3 style={{ color: 'black' }}>{strings.notifications.unlisted.title}</h3>
                        <p style={{ color: 'black' }}>{strings.notifications.unlisted.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
