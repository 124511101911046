/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { copyToClipboard } from "../../../helpers/clipboardHelper";
import {useStrings} from "../../../contexts/StringsContext";

interface SellOtherModalProps {
    qrCode: string;
    wallet: string;
    nftName: string;
    expiry: number;
}

export const SellOtherModal: React.FC<SellOtherModalProps> = ({ qrCode, wallet, nftName, expiry }) => {

    const strings = useStrings();

    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [countDown, setCountdown] = useState<NodeJS.Timeout | null>(null);
    const [expired, setExpired] = useState<boolean | null>(null);

    useEffect(() => {
        startTimer(15 * 60);
        $('#staticBackdrop').modal('show');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiry]);

    const startTimer = (duration: number): void => {
        if (countDown !== null) {
            clearInterval(countDown);
        }

        let timer = duration;
        let minutes: number, seconds: number;

        setCountdown(
            setInterval(() => {
                minutes = Math.floor(timer / 60);
                seconds = timer % 60;

                setMinutes(minutes);
                setSeconds(seconds);

                if (minutes < 1 && seconds < 1) {
                    if (countDown !== null) {
                        clearInterval(countDown);
                    }
                    setCountdown(null);
                    setExpired(true);
                } else {
                    timer--;
                }
            }, 1000)
        );
    };

    const copyAddress = (): void => {
        const value = $('#walletAddress').html();
        copyToClipboard(value);
        $('#copied').fadeIn();
    };

    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="staticBackdropLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img pt-4"></div>
                        {!expired && (
                            <div className="timer-area">
                                <div className="time-box">
                                    <h3>{minutes}</h3>
                                    <p>{strings.notifications.sellOtherNft.minutes}</p>
                                </div>
                                <div className="time-box box-one">
                                    <h3>{seconds}</h3>
                                    <p>{strings.notifications.sellOtherNft.seconds}</p>
                                </div>
                            </div>
                        )}
                        {expired && (
                            <div className="timer-area">
                                <span>{strings.notifications.sellOtherNft.paymentExpired}</span>
                            </div>
                        )}
                        <div className="send-address text-center">
                            <h2>{strings.notifications.sellOtherNft.sendNft}</h2>
                            <p>
                                <br />
                                <span id="walletAddress">{wallet}</span>
                            </p>
                            <a onClick={copyAddress}>{strings.notifications.sellOtherNft.copyAddress}</a>
                            <p id="copied" style={{ display: 'none', color: 'black' }}>
                                {strings.notifications.sellOtherNft.copied}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};